/**
 * @file components/password/rest_password_email.js
 */

import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom"; // eslint-disable-line no-unused-vars
import { Form, Button } from 'react-bootstrap';
import { utils } from '../../utils/utils_general';
import { utils_validation } from '../../utils/utils_validation';
import { rgc_request_password_reset_app } from '../../utils/validationrules_api_app';

import { PAGES } from '../../constants/pages';
import { RESET_PWD_ERROR_DISPLAY, UNEXPECTED_ERROR, RESPONSE_CODE } from '../../constants/errors';
import { login_post } from '../../actions/loginAction';
import { send_reset_password_email_post } from '../../actions/passwordAction';


class ResetPasswordSendEmail extends React.Component {
    constructor() {
        super();
        this.state = {
            submitted: false,
            email: "",
            errors: {},
            jwt: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ errors: {} })
        const data = {
            email: this.state.email
        }

        const errors = utils_validation.validate(rgc_request_password_reset_app, data)
        if (!utils.is_obj_empty(errors)) {
            this.setState({ errors })
        } else {
            this.sendEmail(data)
        }
    }


    sendEmail(data) {
        const success = (success) => {
            this.props.history.push(PAGES.RESET_PASSWORD_SEND_EMAIL_COMPLETE)
        };
        const fail = (error) => {
            if (error.response.status === RESPONSE_CODE["401_unauthorized"]) {
                this.setState({ errors: { system: RESET_PWD_ERROR_DISPLAY.USER_NOT_FOUND} })
            } else {
                this.setState({ errors: { system: UNEXPECTED_ERROR } })
            }
        };
        this.props.send_reset_password_email_post(data)
        .then(response => success(response))
        .catch(error => fail(error));
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }


    render() {
        return (
            <section className="reset-password container-small">

                <h1 className="mb-3 mt-3 text-center">[Forgot Password]</h1>
                <div className="mb-5 text-center"> Enter the email address associated with your account and we'll send you an email with instructions and a link to create a new password.</div>

                <Form noValidate onSubmit={e => this.handleSubmit(e)}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control onChange={e => this.handleChange(e)} name="email" type="email" placeholder="Enter email" />
                        {this.state.errors.email ?
                            <Form.Text className="text-danger">
                                Please provide a valid email.
                            </Form.Text>
                            : null}
                    </Form.Group>

                    {this.state.errors.system ?
                        <div className="mb-3 text-center">
                            <Form.Text className="text-danger">
                                {this.state.errors.system}
                            </Form.Text>
                        </div>
                        : null}

                    <Button className="mt-5" variant="primary" type="submit" block>
                        Send reset email
                    </Button>

                    <div className="mt-3 text-center">
                        <Link to={PAGES.LOGIN}><u>Sign in to your account</u></Link>
                    </div>

                </Form>

            </section>
        )
    }
}



const mapStateToProps = (state) => {
    return ({
        ...state
    })
}

export default withRouter(connect(mapStateToProps, { login_post, send_reset_password_email_post })(ResetPasswordSendEmail));
