/**
 * @file components/Modals/ResendUserModel.js
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Modal, Alert } from 'react-bootstrap';
import { resend_app_invite } from '../../actions/appUserAction';
import { PAGES } from "../../constants/pages";
import { RESPONSE_CODE } from '../../constants/errors';

class ResendUserModel extends React.Component {
    constructor() {
        super();
        this.state = {
            buttonEnabled: true,
            respError: false
        }
    }

    resendInvite() {
        
        this.setState({ buttonEnabled: false });
        return this.props.resend_app_invite(this.props.user.email)
            .then(response => {
                this.setState({ buttonEnabled: true });
                this.closeModal();
            })
            .catch(error => {
                if (error.response) 
                {
                    if (error.response.status === RESPONSE_CODE['401_unauthorized']) {
                        this.props.history.push(PAGES.LOGIN);
                    }
                    else
                    {
                        this.setState({ respError: true });
                        this.setState({ buttonEnabled: true });
                    }
                }
            })
    }

    closeModal() {
        this.props.hideModal();
        this.setState({ respError: false });
    }


    render() {
       

        const userEmail = (this.props.user != null ? this.props.user.email : "");
       const name = (this.props.user != null ? this.props.user.first_name : "") + " "+(this.props.user != null ? this.props.user.last_name : "");  
        return (
            <Modal show={this.props.showModal} onHide={this.props.hideModal}
                aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton={false}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        RESEND INVITE
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {this.state.respError ?
                        <Alert variant="danger">
                            <Alert.Heading>Error</Alert.Heading>
                            <p>
                               Unexpected Error
                            </p>
                        </Alert> : null}

                    <p><b>You are about to resend the invite to :  </b></p>
        <p><code>{name} {userEmail}</code></p>
                    <p className='pt-2'>Do you wish to continue?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button disabled={!this.state.buttonEnabled} variant="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                    <Button disabled={!this.state.buttonEnabled} variant="primary" onClick={() => this.resendInvite()}>Continue</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return ({
        ...state,
        survey: ownProps.survey
    })
}


export default withRouter(connect(mapStateToProps, { resend_app_invite })(ResendUserModel));
