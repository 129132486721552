import { REPORT } from "../constants/api";
import { utils_api } from "../utils/utils_api";


export const api_report = {
	sys_kpi(success, fail) {
		let date = Date.now() //avoid caching
		return utils_api.auth_get(`${REPORT.DASH_KPI}?c=`+date, success, fail)
	},

	survey_kpi(success, fail, survey_id) {
		return utils_api.auth_get(`${REPORT.SURVEY_KPI}/${survey_id}`, success, fail)
	},

	user_kpi(success, fail, user_id) {
		return utils_api.auth_get(`${REPORT.USER_KPI}/${user_id}`, success, fail)
	},

	get_logs(success, fail, param) {
		return utils_api.auth_get(REPORT.AUDIT + '&action_type=' + param, success, fail)
	},
	
	get_healthcheck(success, fail) {
		return utils_api.auth_get(REPORT.HEALTHCHECK, success, fail)
    }
    
}