import { injectable } from "inversify"
import { saveAs } from "file-saver";

export type DownloadDataRequest = {
  data: string,
  fileName: string,
  mime?: string,
}

export interface IDownloadDataService {
  downloadData(request: DownloadDataRequest): void
}

@injectable()
export class DownloadDataService implements IDownloadDataService {
  downloadData({ data, fileName, mime = 'text/plain' }: DownloadDataRequest): void {
    const blob = new Blob([data], { type: `${mime || 'text/plain'};charset=utf-8` });

    saveAs(blob, fileName);
  }
}