import { injectable } from "inversify";
import { api } from "src/api/api";
import { APP_USERS_API_URL } from "src/constants/api";
import { AppUser } from "./types/AppUser";

export interface IUSerService {
  findUserByEmail: (email: string) => Promise<AppUser>
}

@injectable()
export class UserService implements IUSerService {
  async findUserByEmail(email: string): Promise<AppUser> {
    const result = await api<AppUser[]>({
      endpoint: APP_USERS_API_URL.USERS_GET,
      params: { email },
    });

    const { data } = result;
    if (data.length === 0) {
      throw new Error(`User With Email '${email}' not found`);
    }

    const [foundUser] = data;

    return foundUser;
  }

}