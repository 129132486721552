import React, { FormEvent, useState } from "react";
import { Button, Spinner } from "react-bootstrap";

// @ts-ignore
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useInjection } from "src/services/ServicesContext";
import { ISpitKitService } from "src/services/SpitKitService";
import { DoDispatch } from "src/services/types/IStore";
import { CreateSpitKitData } from "src/services/types/validations/SpitKitValidations";
import { useValidate } from "src/services/ValidationHooks";
import { doSetCurrentSpitKit, doSetSpitKitErrors } from "src/store/SpitKit/SpitKitActions";
import { SpitKitReducerState } from "src/store/SpitKit/SpitKitReducer";
import { selectSpitKitCurrentOrder, selectSpitKitErrors, selectSpitKitListLoading } from "src/store/SpitKit/SpitKitSelectors";
import { fullName, newSpitKitOrder, Requester, SpitKitOrderEntry } from "src/store/SpitKit/Types";
import FormField from "../global/FormField/FormField";
import StateList, { StateKey } from "../global/StateList/StateList";

type SpitKitCreateFormStateProps = Required<Pick<SpitKitReducerState, 'currentSpitKit' | 'spitKitListLoading' | 'errors'>>;

type SpitKitCreateFormDispatchProps = {
  setCurrentSpitKit: (spitKit: SpitKitOrderEntry) => void,
  clearSpitKitErrors: () => void,
}

// These are the props directly used when returning the component: <SpitKitCreateForm prop1={} prop2={} />
export type SpitKitCreateFormComponentProps = {
}

const defaultProps: SpitKitCreateFormComponentProps = {

}

export type SpitKitCreateFormProps = SpitKitCreateFormComponentProps & SpitKitCreateFormStateProps & SpitKitCreateFormDispatchProps;

function SpitKitCreateForm({ currentSpitKit, spitKitListLoading, setCurrentSpitKit, errors, clearSpitKitErrors }: SpitKitCreateFormProps) {

  const { email, app_user_id } = currentSpitKit;

  const [addressLine1, setAddressLine1] = useState<string>('');
  const [addressLine2, setAddressLine2] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zip, setZip] = useState<string>('');

  const spitKitService = useInjection<ISpitKitService>('spitKitService');

  const { getError, clearError, validate, hasErrors } = useValidate<CreateSpitKitData>('CREATE_SPIT_KIT_VALIDATION');

  const handleChange = (fieldName: keyof CreateSpitKitData, valueChanged: (value: string) => void) => (e: React.FormEvent<HTMLInputElement>) => {
    clearError(fieldName);
    valueChanged(e.currentTarget.value);
  };

  const handleAddressLine1Change = handleChange('address_line1', setAddressLine1);
  const handleAddressLine2Change = handleChange('address_line2', setAddressLine2);
  const handlePhoneChange = handleChange('order_phone', setPhone);
  const handleCityChange = handleChange('city', setCity);
  const handleStateChange = handleChange('state', setState);
  const handleZipChange = handleChange('zip', setZip);

  const handleBack = () => {
    setCurrentSpitKit(newSpitKitOrder());
    clearSpitKitErrors();
  }

  const handleSpitKitCreateSubmit = async (e: FormEvent) => {
    e.preventDefault();
    clearSpitKitErrors();

    const createSpitKitData: CreateSpitKitData = {
      address_line1: addressLine1,
      address_line2: addressLine2 || ' ',
      app_user_id,
      city,
      zip,
      google_verified: false,
      state: state as StateKey,
      order_phone: phone,
    };

    if (!validate(createSpitKitData)) {
      return;
    }

    await spitKitService.createSpitKitOrder(createSpitKitData);
  }

  return (
    <form onSubmit={handleSpitKitCreateSubmit}>
      <FormField controlId="orderEmail" label="Email" loaded value={email} displayOnly />

      <FormField controlId="orderName" label="Name" loaded value={fullName(currentSpitKit as Requester)} displayOnly />

      <FormField controlId="orderAddress" label="Address Line 1" loaded errorMessage={getError('address_line1')}
        value={addressLine1}
        onChange={handleAddressLine1Change}
      />

      <FormField controlId="orderAddress-line2" label="Address Line 2" loaded errorMessage={getError('address_line2')}
        value={addressLine2}
        onChange={handleAddressLine2Change}
      />

      <FormField controlId="orderCity" label="City" loaded errorMessage={getError('city')}
        value={city}
        onChange={handleCityChange}
      />

      <FormField controlId="orderState" label="State" loaded errorMessage={getError('state')} as={StateList}
        value={state as StateKey}
        onChange={handleStateChange}
      />

      <FormField controlId="zip" label="Zip" loaded errorMessage={getError('zip')}
        value={zip}
        onChange={handleZipChange}
      />

      <FormField controlId="orderPhone" label="Phone" loaded errorMessage={getError('order_phone')}
        value={phone}
        onChange={handlePhoneChange}
      />

      <div className="text-danger text-right">
        {errors.map((e, index) => (
          <p key={index}>{e.message}</p>
        ))}
      </div>

      {spitKitListLoading && (
        <div className="text-right">
          <Spinner animation="border" />
        </div>
      )}

      {!spitKitListLoading && (
        <div className="text-right">
          <Button variant="link" onClick={handleBack}>Back</Button>
          <Button variant="primary" type="submit" disabled={hasErrors}>
            Save
          </Button>
        </div>
      )}
    </form>
  )
}

SpitKitCreateForm.defaultProps = defaultProps;

const mapDispatchToProps = (dispatch: DoDispatch): SpitKitCreateFormDispatchProps => ({
  setCurrentSpitKit: (spitKit: SpitKitOrderEntry) => dispatch(doSetCurrentSpitKit(spitKit)),
  clearSpitKitErrors: () => dispatch(doSetSpitKitErrors([])),
});

const mapStateToProps = createStructuredSelector<any, SpitKitCreateFormStateProps>({
  currentSpitKit: selectSpitKitCurrentOrder,
  spitKitListLoading: selectSpitKitListLoading,
  errors: selectSpitKitErrors,
});

export default connect(mapStateToProps, mapDispatchToProps)(SpitKitCreateForm);