import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { PAGES } from "src/constants/pages";
import { SpitKitOrderEntry } from "src/store/SpitKit/Types";

export type SpitKitOptionsProps = {
  order: SpitKitOrderEntry,
  onDeleteRequested: (order_id: string) => void,
  onCancelRequested: (order_id: string) => void
};

function SpitKitOptions({ order, onDeleteRequested, onCancelRequested }: SpitKitOptionsProps) {

  const { order_id, status } = order;

  if(status === "deleted"){
    return null;
  }

  const handleDropdownClicked = (e: React.MouseEvent) => e.stopPropagation();

  return (
    <Dropdown onClick={handleDropdownClicked}>
      <Dropdown.Toggle id={`spit-kit-options-${order_id}`} variant="light" size="sm">
        Options
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {status === "ordered" && <Dropdown.Item onClick={() => onCancelRequested(order_id)}>Cancel Order</Dropdown.Item>}
        <Dropdown.Item className="text-danger" onClick={() => onDeleteRequested(order_id)}>Delete Order</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item href={`${PAGES.SPIT_KIT_EDIT}/${order_id}`}>
          <FontAwesomeIcon icon={faPencilAlt} /> Edit Order
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default SpitKitOptions;