/**
 * @file components/login/linkexpired.js
 */

import React from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import { PAGES } from '../../constants/pages';
import { utils } from '../../utils/utils_general';
import { SIGNUP_ERROR_DISPLAY, RESPONSE_CODE } from '../../constants/errors';
import {login_post} from '../../actions/loginAction';
import {request_invite_post} from '../../actions/requestInviteAction';


class LinkExpired extends React.Component{
	constructor() {
		super();
		this.state = {
            submitted: false,
            user: null,
            errors: {}
        }
	}

    componentDidMount() {
        const token = utils.get_url_param(this.props.location.search, 'token');
        if (token) {
            this.props.login_post({token})
            .then(response => {
                if (response.token_type !== 'registration') {
                    this.setState({errors: {system: SIGNUP_ERROR_DISPLAY.TOKEN_INVALID}});
                }
            })
            .catch(error => {
                if (error.response.status === RESPONSE_CODE['413_already_active']) {
                    this.props.history.push(PAGES.LOGIN);
					//token should be expired, so if expired, then do nothing to handle it
                } else if (error.response && error.response.status && error.response.status !== RESPONSE_CODE['412_token_expired']) {
					this.setState({errors: {system: SIGNUP_ERROR_DISPLAY.TOKEN_INVALID}})
                }
            });
        } else {
			this.setState({errors: {system: SIGNUP_ERROR_DISPLAY.TOKEN_INVALID}})
		}
    }

	render(){
		return (
			<section className="linkexpired container">
                <section className="signup-link container text-center ">
                    <div className="mb-5 mt-5">icon here</div>
                    <h1 className="mb-3 mt-3">[Link Expired Subhead]</h1>
                    <div className="mb-5 mt-5">img here</div>
                    <div className="mb-5">Informative message about how link has expired after x hours/mins. Will tell participant what the next step.</div>

                    <div>
                        {this.state.errors.system ? 
                        <div className="mb-3 text-center text-danger">
                            {this.state.errors.system}
                        </div>
                    : null}
                    </div>
                </section>
			</section>
		)
	}
}


const mapStateToProps = (state, ownProps) => {
	return ({
        ...state,
        location: ownProps.location
	})
}

export default withRouter(connect(mapStateToProps, {login_post, request_invite_post})(LinkExpired));
