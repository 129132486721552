import React from "react";
import { faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

export type SortRequestedEvent = {
  columnName?: string, currentSortOrder: 'asc' | 'desc' 
};

export type SortColumnHeaderProps = {
  columnName?: string,
  text: string,
  active: boolean,
  sortOrder: "asc" | "desc",
  onSortRequested: (params: SortRequestedEvent) => void,
};

function SortColumnHeader({ text, active, sortOrder, columnName, onSortRequested }: SortColumnHeaderProps) {
  return (
    <Button variant="link"
      className={"p-0 text-decoration-none text-dark font-weight-bold"}
      onClick={() => onSortRequested({ columnName, currentSortOrder: sortOrder })}>
      {text}
      <div className="fa-stack small" style={{ height: "1em"}}>
        <FontAwesomeIcon icon={faSort} color={"#ccc"} fixedWidth className="fa-stack-1x" />
        {active && sortOrder === "asc" && <FontAwesomeIcon icon={faSortUp} fixedWidth className="fa-stack-1x" />}
        {active && sortOrder === "desc" && <FontAwesomeIcon icon={faSortDown} fixedWidth className="fa-stack-1x" />}
      </div>
    </Button>
  );
}

const defaultProps: SortColumnHeaderProps = {
  text: '',
  active: false,
  sortOrder: "asc",
  onSortRequested: () => { },
};

SortColumnHeader.defaultProps = defaultProps;

export default SortColumnHeader;