import React from "react";
import NoEntriesRow from "./NoEntriesRow";
import TableLoadingFiller from "./TableLoadingFiller";

// These are the props directly used when returning the component: <TableBody prop1={} prop2={} />
export type TableBodyComponentProps = {
  isLoading: boolean,
  columns: number,
  rows: number,
  emptyMessage?: string,
  children?: React.ReactChild | React.ReactChild[]
}

export type TableBodyProps = TableBodyComponentProps;

function TableBody({
  isLoading,
  columns,
  rows,
  emptyMessage,
  children,
}: TableBodyProps) {

  if (isLoading) {
    return (
      <tbody>
        <TableLoadingFiller rows={Math.max(4, rows)} cols={columns} />
      </tbody>
    );
  }

  if (rows === 0) {
    return (
      <tbody>
        <NoEntriesRow colSpan={columns} cellClassName={"text-muted text-center"}>
          {emptyMessage}
        </NoEntriesRow>
      </tbody>
    );
  }

  return (
    <tbody>
      {children}
    </tbody>
  );
}

const defaultProps: TableBodyComponentProps = {
  isLoading: true,
  columns: 1,
  rows: 0,
}

TableBody.defaultProps = defaultProps;

export default TableBody;