/**
 * @file components/login/consent.js
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button,} from 'react-bootstrap';
import { UNEXPECTED_ERROR, RESPONSE_CODE } from '../../constants/errors';
import { PAGES } from "../../constants/pages";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getPolls } from '../../actions/pollAction';

import DeletePollModal from '../modals/delete-poll-modal';

import moment from 'moment';

import {faPollH, faPoll,} from '@fortawesome/free-solid-svg-icons'
import { FEATURE } from '../../constants/features';


class Polls extends React.Component {

	uploadIntervalID = 0;

	constructor() {
		super();
		this.state = {
			errors: {},
			polls: null,
			pollPagination:null,
			
			currentPage: 1,
			prevPage: null,
			totalPages: 1,
			totalPolls: null,

			paginationSize: 50,
			modalIsOpen: false,
			modalError: false,
			

		
		}

	
	}

	componentDidMount() {

		
		if(!FEATURE.POLL) //Feature Flag
		return 
		
		if (this.props.location.state) {
			this.setState({ currentPage: (this.props.location.state.pageNum) ? this.props.location.state.pageNum : this.state.currentPage });
			this.Polls(this.props.location.state.pageNum);
		}
		else {
			this.Polls(this.state.currentPage);
		}


		
	}
   
	Polls(pageNum) 
	{
	
		


		return this.props.getPolls(pageNum)
			.then(response => {
				const currentPage = pageNum;

				var records=response.data.polls;
				
				if(!records.length)
				{
					this.handlerError("Did not find any record")
					return
				}
				var n,p;

			
				if(records.length >= 50)
				{
					if(pageNum===1)
					{
						n = pageNum +1;
						p =0;
					}
					else
					{
						n=pageNum +1;
						p = pageNum -1;

					}
			  }
			  else
			  {
				  n=0;
				  p = pageNum-1;
				 
			  }

                const nextPage = n;
                const prevPage =p;
				
				
				this.setState({
					currentPage,
					nextPage,
					prevPage,
					polls: response.data.polls,
					pollPagination: response.data.pagination				
				})
			})
			.catch(error => {
			
			
				if (error.response) {
					if (error.response.status === RESPONSE_CODE['401_unauthorized']) 
					{
						this.props.history.push(PAGES.LOGIN);
					}
				}
				else {
					this.handlerError(UNEXPECTED_ERROR)
				}
			})
	}


	

	handleAction(poll) 
	{
		this.setState({ selectedPollItem: poll })
		this.setState({ modalIsOpen: !this.state.modalIsOpen });
    }

	handlerError(error) {
		this.setState({ errors: { system: error } })
	}

	renderSurveySummary() {
		if (!this.state.pollPagination) {
			return null
		}
		return (

			<div className="row pt-4">

				<div className="col-xl-4 col-md-4 mb-4">
					<div className="card border-left-info h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-s font-weight-bold text-info text-uppercase mb-1"> Polls</div>
									<div className="h1 mb-0 font-weight-bold text-muted">{this.state.pollPagination.total_count}</div>
								</div>
								<div className="col-auto  fa-3x  text-gray-300">
									<FontAwesomeIcon icon={faPollH} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-4 col-md-4 mb-4">
					<div className="card border-left-info h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-s font-weight-bold text-info text-uppercase mb-1">Active</div>
									<div className="h1 mb-0 font-weight-bold text-muted">{this.state.pollPagination.active_count}</div>
								</div>
								<div className="col-auto  fa-3x  text-gray-300">
									<FontAwesomeIcon icon={faPoll} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-4 col-md-4 mb-4">
					<div className="card border-left-info h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-s font-weight-bold text-info text-uppercase mb-1">Inactive</div>
									<div className="h1 mb-0 font-weight-bold text-muted">{this.state.pollPagination.inactive_count}</div>
								</div>
								<div className="col-auto  fa-3x  text-gray-300">
									<FontAwesomeIcon icon={faPoll} />
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>


		)
	}
	
	
	momentFormat(sourceDate)
   {
    if(sourceDate)
    return moment(sourceDate).format('MMMM D YYYY  h:mm:ss a');
	
	return 'NA'

   }
	
	
	renderPolls() {
		

		
		if (!this.state.polls) {
			return null
		}

		const polls = this.state.polls
		 
		let addModalClose = () => {
			this.setState({ modalIsOpen: false });
			this.Polls(this.state.currentPage);
           
        }

		return (


		
			<div className="container-fluid">
  			<DeletePollModal showModal={this.state.modalIsOpen}  hideModal={addModalClose}  poll={this.state.selectedPollItem}/> 
  
  
			  <React.Fragment>{this.renderSurveySummary()}</React.Fragment>

			  <div className="card mb-4">
            <div className="card-header py-3">
            
            <p className="m-0  text-gray-600">Displaying {polls.length} question(s) 
            &nbsp;&nbsp;
           
            </p>   
           
            </div>
            <div className="card-body">
              <div className="table-responsive">
			
			<table className="table " width="100%">
				<thead>

					<tr>
						<th width="20%"> Created</th>
						<th width="10%">ID</th>

						<th width="60%">Question</th>
						<th width="10%">Status</th>
						

					</tr>
				</thead>


				<tbody>

					{polls.map((poll, key) =>
						(


					  <tr key={key} >
						<td className="p-0" ><small>{this.momentFormat(poll.created)}</small></td>						

						<td className="p-0" ><small>{poll.poll_qn_id}</small></td>						
						<td className="p-0" ><small>{poll.title}</small></td>
						<td className="p-0 text-uppercase" ><small></small>
						
						
						<div className="text-center" style={{ marginTop: '5px' }}>
						<Button variant={poll.status === 'active' ? "outline-danger" : "outline-success"} size="sm" className="mr-1 btn-small" onClick={() => this.handleAction(poll)}>{poll.status === 'active' ? "Deactivate" : "Activate"}</Button>

                                        {
                                         

                                         /*
                                        <Button variant="outline-primary" size="sm btn-small" onClick={() => this.handleDownloadSurvey(survey.survey_id)}>Export</Button>
                                         */     
                                        }
                                        </div>
							<p></p>
						
						</td>
								



							</tr>
						))}

				</tbody>
			</table>
			</div>
        </div>
        </div>

                
                

			</div>
		)

	}

	handleNext(e) 
	{
		this.handlerError("");
        this.Polls(this.state.nextPage);
	}

	handlePrev(e) {
		this.handlerError("");
		this.Polls(this.state.prevPage);
	}

	renderPagination() {
	
		
		if (!this.state.polls) {
			
			return null
		}
		
		return (
			<div className="row p-3">
				<div className='col-12 text-right'>
					<div className='p-0'>
						{
							

							this.state.currentPage > 1 ? ((this.state.currentPage - 1) * this.state.paginationSize+1) : 1
						}
						-
						{
							
							this.state.polls.length === this.state.paginationSize ? this.state.currentPage*this.state.paginationSize : (this.state.currentPage-1)*this.state.paginationSize+this.state.polls.length
						}
						<Button variant="link" onClick={e => this.handlePrev(e)} className='btn-link nav-arrow p-0' disabled={this.state.prevPage ? false : true}><i className="arrow left"></i></Button>
						<Button variant="link" onClick={e => this.handleNext(e)} className='btn-link nav-arrow p-0' disabled={this.state.nextPage ? false : true}><i className="arrow right"></i></Button>
					
                    </div>
				</div>

			</div >
		)
	}



	setParam(q,name)
	{
		this.setState({
					
			param:q,
			reportName: name,
			anlogs: null


		})

	}
	

	render() {
		   
		if(!FEATURE.POLL)
		return <div className="text-center"><h3>Not available</h3></div>
		
		return (
			<section className="surveys container">

				


	

				<div className='mt-3 mb-3 '>
					<div className="mb-3 text-muted mb-0 text-gray-800"> <span className="h3">Polls</span> &nbsp; &nbsp;</div>
					{this.renderPolls()}
				</div>
				{this.renderPagination()}
			</section>

		)
	}
}


const mapStateToProps = (state, ownProps) => {
	return ({
		...state,
		location: ownProps.location
	})
}


export default withRouter(connect(mapStateToProps, {getPolls})(Polls));