import {REDUX_ACTION} from "../constants/reduxAction"

const DEFAULT_STATE = false;

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case REDUX_ACTION.SPINNER:
            return action.data || true;
        case REDUX_ACTION.SPINNER_COMPLETE:
            return false;
        default:
            return state;
    }
}