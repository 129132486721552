import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useInjection } from "src/services/ServicesContext";
import { ISpitKitService } from "src/services/SpitKitService";
import { fullName, Requester, SpitKitOrderEntry } from "src/store/SpitKit/Types";
import { SpitKitDate } from "./SpitKitDate";
import StateList, { allStates, StateKey } from "../global/StateList/StateList";
import { PAGES } from "src/constants/pages";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useValidate } from "src/services/ValidationHooks";
import { UpdateSpitKitData } from "src/services/types/validations/SpitKitValidations";
import SpitKitStatus from "./SpitKitStatus";
import FormField from "../global/FormField/FormField";

// These are the props directly used when returning the component: <SpitKitForm prop1={} prop2={} />
export type SpitKitFormComponentProps = {
  order?: SpitKitOrderEntry,
  loaded: boolean,
}

export type SpitKitFormProps = SpitKitFormComponentProps & RouteComponentProps;

function SpitKitForm({ order, loaded, history }: SpitKitFormProps) {

  const [addressLine1, setAddressLine1] = useState<string>('');
  const [addressLine2, setAddressLine2] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [trackingNumber, setTrackingNumber] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zip, setZip] = useState<string>('');

  const spitKitService = useInjection<ISpitKitService>("spitKitService");

  const { order_id: orderId, order_phone, address_line1, address_line2, status, created, updated, serial_id, sku_id, tracking_number, city: originalCity, state: originalState, zip: originalZip, google_verified: originalGoogleVerified, ...requester } = order || {};

  useEffect(() => {
    setAddressLine1(address_line1 || '');
    setAddressLine2(address_line2 || '');
    setPhone(order_phone || '');
    setTrackingNumber(tracking_number || '');
    setCity(originalCity || '');
    setState(originalState || '');
    setZip(originalZip || '');
  }, [loaded, address_line1, order_phone, address_line2, tracking_number, originalCity, originalState, originalZip]);

  const { validate, getError, clearError } = useValidate<UpdateSpitKitData>('UPDATE_SPIT_KIT_VALIDATION');

  const handleChange = (fieldName: keyof UpdateSpitKitData, valueChanged: (value: string) => void) => (e: React.FormEvent<HTMLInputElement>) => {
    clearError(fieldName);
    valueChanged(e.currentTarget.value);
  };

  const handleAddressLine1Change = handleChange('address_line1', setAddressLine1);
  const handleAddressLine2Change = handleChange('address_line2', setAddressLine2);
  const handlePhoneChange = handleChange('order_phone', setPhone);
  const handleTrackingNumberChange = handleChange('tracking_number', setTrackingNumber);
  const handleCityChange = handleChange('city', setCity);
  const handleStateChange = handleChange('state', setState);
  const handleZipChange = handleChange('zip', setZip);

  const handleSaveChanges = async () => {
    if (!orderId) {
      return;
    }
    const google_verified = addressLine1 === address_line1
      && city === originalCity
      && state === originalState
      && (originalGoogleVerified || false);

    const updateSpitKitFields: UpdateSpitKitData = {
      address_line1: addressLine1,
      address_line2: addressLine2 || ' ',
      order_phone: phone,
      city: city || '',
      state: (state || '') as StateKey,
      zip: zip || '',
      google_verified: google_verified || false,
      tracking_number: trackingNumber || ' ',
    };

    const isValid = validate(updateSpitKitFields);

    if (!isValid) {
      return;
    }

    await spitKitService.updateSpitKitOrder({
      order_id: orderId,
      ...updateSpitKitFields,
    });
    history.push(`${PAGES.SPIT_KIT_EDIT}/${orderId}`);
  }

  return (
    <form>
      <FormField controlId="orderId" label="Order ID" loaded={loaded} value={orderId} displayOnly />

      <FormField controlId="orderDate" label="Order Date" loaded={loaded} displayOnly>
        <SpitKitDate date={created} includeTime />
      </FormField>

      <FormField controlId="orderName" label="Name" loaded={loaded} value={requester ? fullName(requester as Requester) : ''} displayOnly />

      <FormField
        controlId="orderAddress"
        label="Address Line 1"
        loaded={loaded}
        value={addressLine1}
        errorMessage={getError('address_line1')}
        onChange={handleAddressLine1Change}
        displayOnly={status !== "ordered"}
      />

      <FormField
        controlId="orderAddress-line2"
        label="Address Line 2"
        loaded={loaded}
        value={addressLine2}
        errorMessage={getError('address_line2')}
        onChange={handleAddressLine2Change}
        displayOnly={status !== "ordered"}
      />

      <FormField
        controlId="orderCity"
        label="City"
        loaded={loaded}
        value={city}
        errorMessage={getError('city')}
        onChange={handleCityChange}
        displayOnly={status !== "ordered"}
      />

      <FormField
        as={StateList}
        controlId="orderState"
        label="State"
        loaded={loaded}
        value={state as StateKey}
        errorMessage={getError('state')}
        displayOnly={status !== "ordered"}
        onChange={handleStateChange}
      />

      <FormField
        controlId="zip"
        label="Zip"
        loaded={loaded}
        value={zip}
        errorMessage={getError('zip')}
        displayOnly={status !== "ordered"}
        onChange={handleZipChange}
      />

      <FormField controlId="orderEmail" label="Email" loaded={loaded} value={(requester as Requester)?.email} displayOnly />

      <FormField
        controlId="orderPhone"
        label="Phone" loaded={loaded}
        errorMessage={getError('order_phone')}
        value={phone}
        onChange={handlePhoneChange}
      />

      <FormField controlId="orderStatus" label="Status" loaded={loaded} displayOnly>
        <SpitKitDate date={updated} includeTime className="mr-2" />
        {status !== undefined && <SpitKitStatus status={status} />}
      </FormField>

      <FormField controlId="orderSerialId" label="Serial ID" loaded={loaded} value={serial_id} displayOnly />

      <FormField controlId="orderDate" label="SKU" loaded={loaded} value={sku_id} displayOnly />

      <FormField
        controlId="trackingNumber"
        label="Tracking Number"
        loaded={loaded}
        value={trackingNumber}
        errorMessage={getError('tracking_number')}
        displayOnly={status !== "ordered"}
        onChange={handleTrackingNumberChange}
      />

      <div className="text-right">
        <Button variant="primary" disabled={!loaded} onClick={handleSaveChanges}>
          Save Changes
        </Button>
      </div>
    </form>
  )
}

const defaultProps: SpitKitFormComponentProps = {
  loaded: false,
}

SpitKitForm.defaultProps = defaultProps;

export default withRouter(SpitKitForm);