import { REDUX_ACTION } from "../constants/reduxAction"

const DEFAULT_STATE = null;

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case REDUX_ACTION.SURVEY_PUBLISH:
            return action.data
        case REDUX_ACTION.SURVEY_DELETE:
            return action.data
        case REDUX_ACTION.SURVEY_DOWNLOAD:
            return action.data
        default:
            return state;
    }
}