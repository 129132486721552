/**
 * @file components/Modals/publishSurveyModal.js
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

class DownloadSurveyModal extends React.Component {
 

    closeModal() {
        this.props.hideModal();
    }


    render() {
        return (
            <Modal show={this.props.showModal} onHide={this.props.hideModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        EXPORT ALL SURVEY
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>I acknowledge that I have reviewed this data file and consent to providing this data back to Regeneron or Research within my Organization.</p>
                    
                    <p className='pt-2'>Do you wish to continue?</p>

                    <p className='pt-2'><strong>Please note that it may take some time to get the survey files ready for download. You will be prompted ones the file is ready.</strong></p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                    <Button variant="primary" onClick={() => this.props.download()}>Continue</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return ({
        ...state,
        survey: ownProps.survey
    })
}


export default withRouter(connect(mapStateToProps, { })(DownloadSurveyModal));
