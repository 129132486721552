import { REDUX_ACTION } from "../constants/reduxAction.js";
import { utils } from '../utils/utils_general';
import { api_request_invite } from '../api/api_request_invite';

export function request_invite_post(data) {
    return (dispatch) => {
        utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER)
        const success = (response) => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
        const fail = error  => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
        return api_request_invite.request_invite(success, fail, data)
    }
}