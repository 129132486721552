import React from 'react';
import styles from "./Placeholder.module.scss";
import classnames from "classnames/bind";
import { range } from "lodash";

const cx = classnames.bind(styles);

export type PlaceholderProps = {
    glow?: boolean,
    inline?: boolean,
    height?: number,
    width?: number,
};

const defaultProps: PlaceholderProps = {
    glow: true,
    inline: false,
    height: 1,
    width: 1,
};

export default function PlaceholderComponent({ glow, inline, height, width }: PlaceholderProps) {

    return (
        <div className={cx(styles.placeholder, { [styles.glow]: glow, [styles.inline]: inline })}>
            {range(0, width).map(n => <span key={n}>&nbsp;</span>)}
            {range(0, height).map(n => <br key={n} />)}
        </div>
    );
}

PlaceholderComponent.defaultProps = defaultProps;
