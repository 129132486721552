import React from "react";
import { FormControlProps } from "react-bootstrap";
import { Form } from "react-bootstrap";

export const allStates = {
  "AK": "Alaska",
  "AL": "Alabama",
  "AR": "Arkansas",
  "AS": "American Samoa",
  "AZ": "Arizona",
  "CA": "California",
  "CM": "Northern Mariana Islands",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DC": "District of Columbia",
  "DE": "Delaware",
  "FL": "Florida",
  "GA": "Georgia",
  "GU": "Guam",
  "HI": "Hawaii",
  "IA": "Iowa",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "MA": "Massachusetts",
  "MD": "Maryland",
  "ME": "Maine",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MO": "Missouri",
  "MS": "Mississippi",
  "MT": "Montana",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "NE": "Nebraska",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NV": "Nevada",
  "NY": "New York",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PA": "Pennsylvania",
  "PR": "Puerto Rico",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TT": "Trust Territories",
  "TX": "Texas",
  "UT": "Utah",
  "VA": "Virginia",
  "VI": "Virgin Islands",
  "VT": "Vermont",
  "WA": "Washington",
  "WI": "Wisconsin",
  "WV": "West Virginia",
  "WY": "Wyoming",
};

export type StateKey = keyof (typeof allStates);

export const StateKeys: StateKey[] = Object.entries(allStates).map(([key]) => key as StateKey);

// These are the props directly used when returning the component: <StateList prop1={} prop2={} />
export type StateListComponentProps = {
  value?: StateKey,
  displayFullStateName?: boolean,
} & FormControlProps;

const defaultProps: StateListComponentProps = {
  displayFullStateName: false,
}

export type StateListProps = StateListComponentProps;

function StateList({ value, readOnly, displayFullStateName, ...formControlProps }: StateListProps) {
  if (readOnly) {
    return (<Form.Control
      plaintext
      readOnly
      value={displayFullStateName ? allStates[value as StateKey] : value}
    />)
  }

  return (
    <Form.Control readOnly={readOnly} {...formControlProps} as="select" value={value}>
      <option key={'no-state'} value={''}>-- Select one state --</option>
      {Object.entries(allStates).map(([stateCode, stateName]) => (
        <option key={stateCode} value={stateCode}>{displayFullStateName ? stateName : stateCode}</option>
      ))}
    </Form.Control>
  )
}

StateList.defaultProps = defaultProps;

export default StateList;