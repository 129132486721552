
import * as CONFIG from './api_config';

// const FRONTEND_DEV = "rgcdtp-portal-dev.klickcloud.net";
// const FRONTEND_QA = "rgcdtp-portal-qa.klickcloud.net";
// const FRONTEND_RGCSTAGE = "rgcdtp-portal-rgcstage.klickcloud.net";

// const API_DEV = "rgcdtp-api-dev.klickcloud.net/api/v1";
// const API_QA = "rgcdtp-api-qa.klickcloud.net/api/v1";
// const API_RGCSTAGE = "rgcdtp-api-rgcstage.klickcloud.net/api/v1";

// const hostname = window && window.location && window.location.hostname;

// const HTTP_PROTOCOL = hostname === 'localhost' ? "https://" : "https://";

// let backendHost;
// let current_environment = null;
// if (hostname === 'localhost') {
//   // backendHost = '127.0.0.1:3000';
//   backendHost = API_DEV;
//   current_environment = 'LOCAL'
// } else if (hostname === FRONTEND_DEV) {
//   backendHost = API_DEV;
//   current_environment = 'DEV'
// } else if (hostname === FRONTEND_QA) {
//   backendHost = API_QA;
//   current_environment = 'QA'
// } else if (hostname === FRONTEND_RGCSTAGE) {
//   backendHost = API_RGCSTAGE;
//   current_environment = 'RGC-STAGE'
// } else {
//   backendHost = API_DEV;
// }

export const URL_ROOT_API = CONFIG.api_config.api_endpoint;

export const ENV_STRING = CONFIG.api_config.current_environment;

export const API_URL = {
    LOGIN_POST: "/portal/login",
    LOGIN_GET: "/portal/login",
    LOGOUT_GET: "/portal/logout",
    USERS_GET: "/portal/users",
    SELF_GET: "/portal/user",
    USER_POST: "/portal/user",
    USER_PUT: "/portal/user",
    RESET_PASSWORD_PUT: "/portal/resetpassword",
    REQUEST_PASSWORD_RESET_POST: "/portal/request_password_reset",
    REQUEST_APP_INVITE_POST: "/portal/request_portal_invite",

    ORDERS: "portal/orders",
    ORDERS_EXPORT: "portal/orders/export",
    ORDERS_IMPORT: "portal/orders/import",
    ORDERS_UPDATE: (order_id) => `portal/orders/${order_id}`,
    ORDERS_UPDATE_STATUS: (order_id) => `portal/orders/${order_id}/status`,
    ORDERS_UPDATE_STATUS_BULK: 'portal/orders/status',
}

export const SURVEY_API_URL = {
  SURVEYS_GET: "/surveys",
  SURVEY_ROOT: "/survey",
  SURVEY_POST_PUBLISH: "/publish",
  SURVEY_DEL_PUBLISH: "/publish",
  SURVEY_GET_DOWNLOAD: "/export/file",
  SURVEYS_GET_DOWNLOAD_ALL: "/surveys/export/file",
  SURVEYS_GET_DOWNLOAD_STATUS: "/surveys/export/status",
  SURVEY_IMPORT_LS: "/surveys/import/limesurvey",
  SURVEY_GROUP: "/surveys/group",
  SURVEY_UPLOAD: "/survey/import/file",
  SURVEY_PREVIEW: "/preview"
}

export const APP_USERS_API_URL = 
{
  USERS_GET: "/app/users",
  APP_USER_PUT: "/app/user",
  APP_USER_REINVITE : "/app/user/request_app_invite"
}

export const APP_CONTENT = 
{
  TERMS_CONTENT_GET: "/config/portal_term",
  PRIVACY_CONTENT_GET: "/config/portal_privacy"
}

export const ANCESTRY = 
{
  UPLOAD: "/ancestry/import/file",
  ANCESTRY_GET_UPLOAD_STATUS: "/ancestry/import/status",
  ANCESTRY_GET: "/ancestry",
  ANCESTRY_LOG: "/report/audit?page_size=50&action_type=ancestry_import_file_result&sort_item=action_date&sort_asc=0"
}

export const POLL = 
{
  IMPORT_POLL: "/poll/import/limesurvey",
  POLLS: "/poll/questions",
  POLLS_STATUS: "/poll/setstatus"
}

export const REPORT = 
{
  AUDIT: "/report/audit?page_size=50&sort_item=action_date&sort_asc=0",
  DASH_KPI: "/report/kpi",
  SURVEY_KPI: "/report/survey",
  USER_KPI: "/report/user",
  CONFIG: "/config/info",
  HEALTHCHECK: "/healthcheck"

}

export const RESPONSE_TYPE = {
    SUCCESS: "success",
    FAILURE: "failure"
}
