// this is temp, we should be fetching it from S3

export const utils_validation = {
    WORKSPACE : {
        APP: 'app',
        APP_API: 'api_app',
        PORTAL: 'portal',
        PORTAL_API: 'api_portal',
        UTILS: 'utils'
    },
    RULE_NAME : {
        FETCH_PARTICIPANT: 'rgc_fetch_participant',
        PASSWORD: 'portaluser_validaterepeatedpassword',
        GETJWT:'getjwt'
    },
    // get_validation_rules: (input_v_rule_name, workspace) => {
    //     let validation_rules_file;
    //     try {
    //         validation_rules_file = require(`../validation_scripts/validationrules_${workspace}.js`);
    //     } catch (e){
    //         throw new Error("cannot find validation workspace : " + workspace);
    //     }

    //     let ruleset;
    //     try {
    //         ruleset = validation_rules_file[input_v_rule_name];    

    //         if (!ruleset)
    //             throw new Error('msg here will get trump');
    //     } catch (e){
    //         throw new Error(`cannot find validation ruleset (workspace : ${workspace}) : ` + input_v_rule_name);
    //     }

    //    return ruleset;
    // },
    validate: (rules, data) => {

        const custom_fn = null;
        const fieldsNeedValidate = rules.filter(f => needsValidate(f, data));
        const fieldsWithData = fieldsNeedValidate.filter(f => (data[f.name] || '').length > 0)

        const rule_keys = rules.map(rule => rule.name);
        const invalidFields = Object.keys(data)
        .filter(item => !rule_keys.includes(item))
        .reduce((acc, f) => {
            acc[f] = ('error-invalid-field');
            return acc;
        }, {})

    
        const emptyError = fieldsNeedValidate
            .filter(f => {
                return (f.type === 'checkbox') ? (data[f.name] || false) === false : (data[f.name] || '').length === 0;
            })
            .reduce((acc, f) => {
                acc[f.name] = ('error-empty');
                return acc;
            }, {})
        

        const invalidPasswordError = fieldsWithData
            .filter(f => f.type === 'password')
            .filter(f => !isPassword(data[f.name]))
            .reduce((acc, f) => {
                acc[f.name] = ('error-invalid-password-format');
                return acc;
            }, {})

        const invalidIntegerError = fieldsWithData
            .filter(f => f.type === 'number')
            .filter(f => !isInteger(data[f.name]))
            .reduce((acc, f) => {
                acc[f.name] = ('error-invalid-input-not-number');
                return acc;
            }, {})
        
        const invalidEmailError = fieldsWithData
            .filter(f => f.type === 'email')
            .filter(f => !isEmail(data[f.name]))
            .reduce((acc, f) => {
                acc[f.name] = ('error-invalid-email');
                return acc;
            }, {})
    
        const invalidZipCodeError = fieldsWithData
            .filter(f => f.type === 'zip_code' || f.type === 'zipcode')
            .filter(f => !isZipCode(data[f.name]))
            .reduce((acc, f) => {
                acc[f.name] = ('error-invalid-zipcode');
                return acc;
            }, {})
            
        const invalidPhoneError = fieldsWithData
            .filter(f => f.type === 'phone_number' || f.type === 'phone')
            .filter(f => !isPhone(data[f.name]))
            .reduce((acc, f) => {
                acc[f.name] = ('error-invalid-phone');
                return acc;
            }, {})
    
        const invalidGuidError = fieldsWithData
        .filter(f => f.type === 'guid')
        .filter(f => !isGuid(data[f.name]))
        .reduce((acc, f) => {
            acc[f.name] = ('error-invalid-guid');
            return acc;
        }, {})            

        const outOfRangeError = fieldsWithData
            .filter(f => f.fixedvalue  !== undefined)
            .filter(f => {
                const formValue = (data[f.name] || '');
                return (f.fixedvalue.map(x => x)).indexOf(formValue) === -1
            })
            .reduce((acc, f) => {
                acc[f.name] = ('error-outOfRange');
                return acc;
            }, {})
    
        const regexError = fieldsWithData
            .filter(f => f.regex  !== undefined)
            .filter(f => {
                var regex = RegExp(f.regex, 'g');
                return !regex.test(data[f.name]);
            })
            .reduce((acc, f) => {
                acc[f.name] = ('error-regex');
                return acc;
            }, {})
    
    
        const customfnError = fieldsWithData
            .filter(f => f.customfn  !== undefined && (typeof f.customfn) === 'function')
            .filter(f => {
                return !f.customfn(data);
            })
            .reduce((acc, f) => {
                acc[f.name] = ('error-customfn');
                return acc;
            }, {})
    
        const otherError = ((typeof custom_fn) === 'function') ? custom_fn(data) : [];
    
        const allErrorsArr = [invalidFields, outOfRangeError, invalidEmailError, invalidZipCodeError, invalidPhoneError, invalidGuidError, emptyError, regexError, customfnError, otherError, invalidPasswordError, invalidIntegerError];
    
        const allErrorObj = {};
        allErrorsArr.forEach(errorObj => {
            for (var key in errorObj) {
                if (errorObj.hasOwnProperty(key)) {
                    allErrorObj[key] = errorObj[key];
                }
            }
        })
        return allErrorObj;
    }
};


const regex_email = '^((([0-9a-zA-Z])+([-._\'+&]))*[0-9a-zA-Z]+@([-0-9a-zA-Z]+[.])+[a-zA-Z]{2,6})((\\s*,\\s*)(([0-9a-zA-Z])+([-._\'+&]))*[0-9a-zA-Z]+@([-0-9a-zA-Z]+[.])+[a-zA-Z]{2,6})*$';
const isEmail = e => {
    const r_email = RegExp(regex_email, 'g');
    const r_len = RegExp('^.{1,64}$', 'g');
    return r_email.test(e) && r_len.test(e);
}

// business logic
const regex_zipcode = '^[0-9]{5}$';
const isZipCode = e => {
    const r_zipcode = RegExp(regex_zipcode, 'g');
    return r_zipcode.test(e)
}

// business logic
const regex_phone = '^(?:(?:\\+?1\\s*(?:[.-]\\s*)?)?(?:\\(\\s*([2-9]\\d{2})\\s*\\)|([2-9]\\d{2}))\\s*(?:[.-]\\s*)?)([2-9]\\d{2})\\s*(?:[.-]\\s*)?([0-9]{4})(?:\\s*(?:#|x\\.?|ext\\.?|extension)\\s*(\\d+))?$';
const isPhone = e => {
    const r_phone = RegExp(regex_phone, 'g');
    return r_phone.test(e)
}


// business logic
const regex_guid = '^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$';
const isGuid = e => {
    const rgx = RegExp(regex_guid, 'g');
    return rgx.test(e)
}


// Password must contain at least one uppercase character and one lowercase character
// Password must contain at least one number
// Password must be at least 8 characters in length
// Password must contain at least one special character: %@#$^&[]{}+=-~_| 
const regex_password = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!%@#$^&[\\]{}+=\\-~_|])(?=.{8,50}$)';

const isPassword = e => {
    const r_password = RegExp(regex_password, 'g');
    return r_password.test(e)
}

const isInteger = e => {
    const rgx = RegExp('^[0-9]+$', 'g');
    return rgx.test(e)
}


const needsValidate = (field, data) => {
    if (field.req)
        return true;

    if (field.reqif) {
        const req_field = field.reqif.split('|')[0];
        const req_value = field.reqif.split('|')[1];
        return (data[req_field]  !== undefined && data[req_field].toString() === req_value);
    }

    if (field.reqfn)
        return field.reqfn(data);

    if (field.dependsif) {
        const req_field = field.dependsif.split('|')[0];
        const req_value = field.dependsif.split('|')[1];
        const needs = (data[req_field]  !== undefined && data[req_field].toString() === req_value);
        return (needs && data[field.name])
    }

    if (field.dependsfn)
        return field.dependsfn(data);

    if (data[field.name])
        return true;

    return false;
  }