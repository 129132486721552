/**
 * @file components/login/consent.js
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SurveyList from './survey_list';
import { Form, Button, Alert } from 'react-bootstrap';
import DownloadSurveyModal from '../modals/download-survey-modal';
import ImportSurveyModal from '../modals/import-survey-modal'
import { UNEXPECTED_ERROR, RESPONSE_CODE } from '../../constants/errors';
import { PAGES } from "../../constants/pages";
import { utils } from '../../utils/utils_general';
import { LOCAL_STORAGE } from '../../constants/localStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { export_surveys_get, get_survey_list_get, ls_import_surveys_post } from '../../actions/surveyAction';

import {faPollH, faPoll, faSync} from '@fortawesome/free-solid-svg-icons'
import { FEATURE } from '../../constants/features';


class Surveys extends React.Component {

	constructor() {
		super();
		this.state = {
			errors: {},
			surveys: null,
			groups: null,
			currentPage: 1,
			nextPage: null,
			prevPage: null,
			surveyPagination: null,
			totalPages: 1,
			totalSurveys: null,
			pageSize: null,
			exportAllSurveyModalOpen: false,
			importSurveyModalOpen: false,
			isDownloading: false,
			syncStatus: false
		}
	}

	componentDidMount() {
		if(!FEATURE.SURVEY) //Feature Flag
		return 
		
		if (this.props.location.state) {
			this.setState({ currentPage: (this.props.location.state.pageNum) ? this.props.location.state.pageNum : this.state.currentPage });
			this.getSurveys(this.props.location.state.pageNum);
		}
		else {
			this.getSurveys(this.state.currentPage);
		}

		if (this.props.isDownloading && !this.state.isDownloading) {
			this.setState({ isDownloading: true });
		}
	}

	getSurveys(pageNum) {
		return this.props.get_survey_list_get(pageNum)
			.then(response => {
				const currentPage = pageNum;
				const nextPage = currentPage < response.pagination.total_pages ? currentPage + 1 : null;
				const prevPage = currentPage > 1 ? currentPage - 1 : null;
				
			
				this.setState({
					currentPage,
					nextPage,
					prevPage,
					surveys: response.surveys,
					groups: response.groups,
					surveyPagination: response.pagination,
					totalPages: response.pagination.total_pages,
					totalSurveys: response.pagination.total_count,
					pageSize: response.pagination.page_size
				})
			})
			.catch(error => {
				if (error.response) {
					if (error.response.status === RESPONSE_CODE['401_unauthorized']) {
						this.props.history.push(PAGES.LOGIN);
					}
				}
				else {
					this.handlerError(UNEXPECTED_ERROR)
				}
			})
	}

	downloadAllSurveys() {
		this.handlerError("");
		this.setState({ exportAllSurveyModalOpen: false });
		this.setState({ isDownloading: true });

		return this.props.export_surveys_get()
			.then(response => {
				utils.set_local_storage(LOCAL_STORAGE.EXPORT_SURVEY_TOKEN, response.data);
				
				this.props.checkExportStatus();

			

				
			})
			.catch(error => {
				this.setState({ isDownloading: false });
				if (error.response) {
					if (error.response.status === RESPONSE_CODE['401_unauthorized']) {
						this.props.history.push(PAGES.LOGIN);
					}
				}
			})
	}

	handlerError(error) {
		this.setState({ errors: { system: error } })
	}

	reloadSurvey() {
		this.getSurveys(this.state.currentPage)
	}


	handleNext(e) {
		this.handlerError("");
		this.getSurveys(this.state.nextPage);
	}

	handlePrev(e) {
		this.handlerError("");
		this.getSurveys(this.state.prevPage);
	}

	renderDownloadProcess() {
		return (
			<div className="downloading-survey-details">
				<div className="text-xs font-weight-bold text-success mb-1">
					<div className="lds-dual-ring mr-2"></div>
					Getting Survey files ready for download
				</div>
			</div>
		)
	}


	handleSync()
    {
		this.handlerError("");
		this.setState({ syncStatus: false });
        return this.props.ls_import_surveys_post().then(response => 
            {
				this.setState({ syncStatus: true });
				this.getSurveys(1);
             
            })
            .catch(error => {
               
					if (error.response) {
						if (error.response.status === RESPONSE_CODE['401_unauthorized']) {
							this.props.history.push(PAGES.LOGIN);
						}
					}
					else {
						this.handlerError(UNEXPECTED_ERROR)
					}
                
            })
         
        
    }
	renderSurveySummary() {
		if (!this.state.surveys) {
			return null
		}
		return (

			<div className="row pt-4">

				<div className="col-xl-3 col-md-3 mb-3">
					<div className="card border-left-info h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-s font-weight-bold text-info text-uppercase mb-1">Surveys</div>
									<div className="h1 mb-0 font-weight-bold text-muted">{this.state.surveyPagination.total_count}</div>
								</div>
								<div className="col-auto  fa-3x  text-gray-300">
									<FontAwesomeIcon icon={faPollH} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-3 mb-3">
					<div className="card border-left-info h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-s font-weight-bold text-info text-uppercase mb-1">Published</div>
									<div className="h1 mb-0 font-weight-bold text-muted">{this.state.surveyPagination.published_count}</div>
								</div>
								<div className="col-auto  fa-3x  text-gray-300">
									<FontAwesomeIcon icon={faPoll} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-3 mb-3">
					<div className="card border-left-info h-100 py-2">
						<div className="card-body">
							<div className="row no-gutters align-items-center">
								<div className="col mr-2">
									<div className="text-s font-weight-bold text-info text-uppercase mb-1">Unpublished</div>
									<div className="h1 mb-0 font-weight-bold text-muted">{this.state.surveyPagination.unpublished_count}</div>
								</div>
								<div className="col-auto  fa-3x  text-gray-300">
									<FontAwesomeIcon icon={faPoll} />
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>


		)
	}
	renderPagination() {
		if (!this.state.surveys) {
			return null
		}
		return (
			<div className="row p-3">
				<div className='col-12 text-right'>
					<div className='p-0'>{this.state.currentPage > 1 ? ((this.state.currentPage - 1) * this.state.pageSize + 1) : this.state.currentPage}-{(this.state.pageSize * this.state.currentPage) > this.state.totalSurveys ? this.state.totalSurveys : this.state.pageSize * this.state.currentPage} of {this.state.totalSurveys}
						<Button variant="link" onClick={e => this.handlePrev(e)} className='nav-arrow p-0' disabled={this.state.prevPage ? false : true}><i className="arrow left"></i></Button>
						<Button variant="link" onClick={e => this.handleNext(e)} className='nav-arrow p-0' disabled={this.state.nextPage ? false : true}><i className="arrow right"></i></Button>

					</div>
				</div>

			</div >
		)
	}

	renderSearch() {
		return (
			<div className="row pt-3">
				<div className='col-8'>
				</div>

				<div className='col-4 '>
					<Form noValidate onSubmit={e => null}>

						<Form.Group>
							<Form.Control onChange={e => null} name="Search" placeholder="Search" />
						</Form.Group>

					</Form>
				</div>
			</div>

		)
	}


	render() {
		
		if(!FEATURE.SURVEY)
		return <div className="text-center"><h3>Not available</h3></div>
		
		
		let modalClose = (reload) => {
			
			this.setState({ exportAllSurveyModalOpen: false });
			this.setState({ importSurveyModalOpen: false });
			this.handlerError("");

			if(reload)
			this.reloadSurvey();

		}

		
		return (
			<section className="surveys container">
				<div className='mt-3 mb-3'>
					<div className="mb-3 text-muted mb-0 text-gray-800"> <span className ="h3">Surveys</span> &nbsp; &nbsp;
					<Button className="mr-3 btn-info" onClick={e => this.setState({ importSurveyModalOpen: true }) }>Import Survey</Button>
						{this.props.isDownloading ?
							<React.Fragment>{this.renderDownloadProcess()}</React.Fragment>
							: <Button variant="outline-info" onClick={e => this.setState({ exportAllSurveyModalOpen: true })}>Export all Surveys</Button>
						}
					               
								   
			&nbsp;&nbsp;<button style={{minWidth: 'auto'}} className="btn btn-sm btn-info" onClick={() => this.handleSync()}>									
            <FontAwesomeIcon icon={faSync} /></button>
            {this.state.syncStatus ? <span>&nbsp;<small>Syched surveys from LimeSurvey.</small></span> : <span></span>}

					</div>
				
				

				</div>

				<DownloadSurveyModal
					showModal={this.state.exportAllSurveyModalOpen}
					hideModal={modalClose}
					download={this.downloadAllSurveys.bind(this)}
				/>

				<ImportSurveyModal
				showModal={this.state.importSurveyModalOpen}
				hideModal={modalClose}  > </ImportSurveyModal>

				<React.Fragment>{this.renderSurveySummary()}</React.Fragment>
				<React.Fragment>{this.renderSearch()}</React.Fragment>
				<SurveyList displaySurveys={this.state.surveys} surveyGroups={this.state.groups} errors={this.handlerError.bind(this)} refreshSurvey={this.reloadSurvey.bind(this)} />
				{this.renderPagination()}

				{this.state.errors.system ?
					<Alert variant="danger">
						<Alert.Heading>Something went wrong</Alert.Heading>
						<p>
							{this.state.errors.system}
						</p>
					</Alert> : null}
			</section>
		)
	}
}


const mapStateToProps = (state, ownProps) => {
	return ({
		...state,
		location: ownProps.location
	})
}

export default withRouter(connect(mapStateToProps, { get_survey_list_get, export_surveys_get, ls_import_surveys_post})(Surveys));