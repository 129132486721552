import Joi, { ObjectSchema } from "joi";
import { StateKeys } from "src/components/global/StateList/StateList";
import { SpitKitOrderEntry } from "src/store/SpitKit/Types";

type SpitKitEntryJoi = SpitKitOrderEntry<
Joi.StringSchema,  // TAddressLine1 = string,
Joi.StringSchema,  // TAddressLine2 = string,
Joi.StringSchema,  // TCity = string,
Joi.StringSchema,  // TState = StateKey,
Joi.StringSchema,  // TZip = string,
Joi.StringSchema,  // TOrderPhone = string,
Joi.BooleanSchema, // TGoogleVerified = boolean,
Joi.StringSchema   // TTrackingNumber = string,
>;


// UPDATE

type UpdateSpitKitFields = 
| 'address_line1'
| 'address_line2'
| 'city'
| 'state'
| 'zip'
| 'order_phone'
| 'google_verified'
| 'tracking_number'

export type UpdateSpitKitData = Pick<SpitKitOrderEntry, UpdateSpitKitFields>

export type UpdateSpitKitValidation = Pick<SpitKitEntryJoi, UpdateSpitKitFields>;

const invalidZipCodeMessage = "Invalid Zip Code";
const fieldIsRequiredMessage = "Required";
const fullContactValidationSchema = {
  address_line1: Joi.string().required().label('Address Line 1'),
  address_line2: Joi.string().optional().label('Address Line 2'),
  city: Joi.string().required().label('City'),
  state: Joi.string().required().valid(...StateKeys).label('State'),
  zip: Joi.string().regex(/^[0-9]{5}$/).messages({
    'any.base': invalidZipCodeMessage,
    'any.regex': invalidZipCodeMessage,
    'string.pattern.base': invalidZipCodeMessage,
  }).label('Zip'),
  order_phone: Joi.string().regex(/^\d{10}$/).messages({
    'any.empty': fieldIsRequiredMessage,
    'any.required': fieldIsRequiredMessage,
    'string.pattern.base': "Invalid Phone",
  }).label('Order Phone'),
  google_verified: Joi.boolean()
}

export const UPDATE_SPIT_KIT_VALIDATION: ObjectSchema<UpdateSpitKitValidation> = Joi.object({
  ...fullContactValidationSchema,
  tracking_number: Joi.string().optional(),
});

// CREATE

type CreateSpitKitFields = 
| 'address_line1'
| 'address_line2'
| 'city'
| 'state'
| 'zip'
| 'order_phone'
| 'google_verified'
| 'app_user_id'

export type CreateSpitKitData = Pick<SpitKitOrderEntry, CreateSpitKitFields>

export type CreateSpitKitValidation = Pick<SpitKitEntryJoi, CreateSpitKitFields>;

export const CREATE_SPIT_KIT_VALIDATION: ObjectSchema<CreateSpitKitValidation> = Joi.object({
  ...fullContactValidationSchema,
  app_user_id: Joi.string().guid().required(),
});