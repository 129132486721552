import React, { useState } from "react";
import { ListGroup } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { PAGES } from "src/constants/pages";
import PlaceholderComponent from "src/components/global/Placeholder/PlaceholderComponent";
import SpitKitActionsModal, { OrderAction, OrderActionStatus } from "./SpitKitActionsModal";
import { RGCStatus } from "src/store/SpitKit/Types";

// These are the props directly used when returning the component: <SpitKitOptionsAside prop1={} prop2={} />
export type SpitKitOptionsAsideComponentProps = {
  orderId?: string,
  status?: RGCStatus,
  loaded: boolean
}

export type SpitKitOptionsAsideProps = SpitKitOptionsAsideComponentProps & RouteComponentProps;

function SpitKitOptionsAside({ orderId, status, loaded, history }: SpitKitOptionsAsideProps) {

  const [action, setAction] = useState<OrderAction>();

  const handleCancel = () => {
    setAction(undefined);
  }

  const handleActionRequested = (requestedAction: OrderAction) => {
    setAction(requestedAction);
  }

  const handleActionStatus = (status: OrderActionStatus) => {
    if(status !== "completed"){
      return;
    }
    setAction(undefined);

    switch (action) {
      case "cancel": {
        history.push(`${PAGES.SPIT_KIT_EDIT}/${orderId}`);
        return;
      }
      case "delete": {
        history.push(PAGES.SPIT_KIT);
        return;
      }
    }
  }

  return (
    <>
      <ListGroup>
        <ListGroup.Item>
          <strong>Options</strong>
        </ListGroup.Item>
        {loaded && (
          <>
            {status === "ordered" && (
              <ListGroup.Item action onClick={() => handleActionRequested("cancel")}>Cancel Order</ListGroup.Item>
            )}
            <ListGroup.Item action className="text-danger" onClick={() => handleActionRequested("delete")}>Delete Order</ListGroup.Item>
          </>
        )}
        {!loaded && (
          <>
            <ListGroup.Item>
              <PlaceholderComponent />
            </ListGroup.Item>
            <ListGroup.Item>
              <PlaceholderComponent />
            </ListGroup.Item>
          </>
        )}
      </ListGroup>

      <SpitKitActionsModal 
        orderToModify={orderId} 
        action={action} 
        onCancel={handleCancel} 
        onActionStatus={handleActionStatus} />
    </>
  )
}

const defaultProps: SpitKitOptionsAsideComponentProps = {
  loaded: false,
}

SpitKitOptionsAside.defaultProps = defaultProps;

export default withRouter(SpitKitOptionsAside);