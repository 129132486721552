import React from 'react';
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { utils } from '../../utils/utils_general';
import { utils_validation } from '../../utils/utils_validation';
import { portal_first_time_signin } from '../../utils/validationrules_api_app';
import { TOKEN_ERROR_DISPLAY, RESPONSE_CODE, PASSWORD_FORMAT_ERRORS, SET_PASSWORD_ERROR_DISPLAY, SET_PASSWORD_ERROR_RESPONSES, UNEXPECTED_ERROR, TERMS_AND_CONDITION_ERROR_DISPLAY } from '../../constants/errors';

import { PAGES } from '../../constants/pages';
import { login_post } from '../../actions/loginAction';
import { set_password_first_time_put } from '../../actions/userAction';

class SetPassword extends React.Component {

    constructor() {
        super();
        this.state = {
            submitted: false,
            password: "",
            passwordFormatErrors: null,
            errors: {},
            user: null,
            termsAndConditions: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const token = utils.get_url_param(this.props.location.search, 'token');
        if (token) {
            this.props.login_post({ token })
                .then(response => {
                    if (response.token_type !== 'registration') {
                        this.setState({ errors: { system: TOKEN_ERROR_DISPLAY.TOKEN_INVALID } });
                    } else if (new Date(response.expiry) < new Date()) {
                        this.handleExpiredToken(token)
                    } else {
                        this.setState({ user: response })
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === RESPONSE_CODE['412_token_expired']) {
                        this.handleExpiredToken(token)
                    } else if (error.response && error.response.status === RESPONSE_CODE['413_already_active']) {
                        this.props.history.push(PAGES.LOGIN);
                    } else {
                        this.setState({ errors: { system: TOKEN_ERROR_DISPLAY.TOKEN_INVALID } })
                    }
                });
        }
        else {
            this.props.history.push(PAGES.LOGIN);
        }
    }

    handleExpiredToken(token) {
        this.props.history.push(PAGES.LINK_EXPIRED + "?token=" + token);
    }


    handleSubmit(e) {
        e.preventDefault();
        this.setState({ errors: {} })


        const data = {
            password: this.state.password
        }

        const errors = utils_validation.validate(portal_first_time_signin, { user_id: this.state.user.user_id, password: data.password, TermsAndConditions: this.state.termsAndConditions })
        if (!utils.is_obj_empty(errors)) {
            const SPR = SET_PASSWORD_ERROR_RESPONSES;
            const SPD = SET_PASSWORD_ERROR_DISPLAY;
            const FORMAT = PASSWORD_FORMAT_ERRORS;
            let passwordFormatErrors = utils.check_password_format(data.password)
            if (errors.password === SPR.PASSWORD_EMPTY) {
                errors.password = SPD.PASSWORD_EMPTY;
            } else if (errors.password === SPR.PASSWORD_INVALID) {
                errors.password = SPD.PASSWORD_INVALID;
            }
            if (passwordFormatErrors.includes(FORMAT.LENGTH) && this.state.password.length >= 8) {
                passwordFormatErrors = passwordFormatErrors.filter(item => item !== FORMAT.LENGTH);
                errors.password = 'Password too long.';
            }
            if (errors.TermsAndConditions === SPR.PASSWORD_EMPTY) {
              errors.termsAndConditions = TERMS_AND_CONDITION_ERROR_DISPLAY.NOT_CHECKED;
            }
            this.setState({ passwordFormatErrors, errors })
        } else {
            errors.termsAndConditions = "";
            this.setPassword(this.state.password, this.state.user.email)
        }

    }

    setPassword(password, email) {
        const success = (resp) => {
            this.props.history.push(PAGES.FIRST_TIME_SET_PASSWORD_COMPLETE);
        };
        const fail = (error) => this.setState({ errors: { password: UNEXPECTED_ERROR } })

        this.props.set_password_first_time_put(password)
            .then(response => success(response))
            .catch(error => fail(error));
    }


    handleChange(e) {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked });
        }
        else {
            this.setState({ [e.target.name]: e.target.value });
        }
        if (this.state.passwordFormatErrors) {
            this.setState({ passwordFormatErrors: null })
        }
    }

    render() {
        return (
            <section className="set-password container">
                <h1 className="mb-3 mt-3 text-center">[Set Your Password]</h1>
                <Form noValidate onSubmit={e => this.handleSubmit(e)}>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control onChange={e => this.handleChange(e)} name="password" type="password" placeholder="Password" />

                        {this.state.errors.password ?
                            <Form.Text className="text-danger">
                                {this.state.errors.password}
                            </Form.Text>
                            : null}
                    </Form.Group>

                    <div className="mb-5">
                        Password Requirements
                                <ul style={{ fontSize: '12px' }}>
                            <li>Must be at least 8 characters long {this.state.passwordFormatErrors && !this.state.passwordFormatErrors.includes(PASSWORD_FORMAT_ERRORS.LENGTH) ? <span>✓</span> : null}</li>
                            <li>Must contain at least 1 capital and 1 lower case letter {this.state.passwordFormatErrors && !this.state.passwordFormatErrors.includes(PASSWORD_FORMAT_ERRORS.CASE) ? <span>✓</span> : null}</li>
                            <li>Must contain at least 1 number {this.state.passwordFormatErrors && !this.state.passwordFormatErrors.includes(PASSWORD_FORMAT_ERRORS.NUMBER) ? <span>✓</span> : null}</li>
                            <li>Must contain at least 1 special character {this.state.passwordFormatErrors && !this.state.passwordFormatErrors.includes(PASSWORD_FORMAT_ERRORS.SPECIAL_CHARACTER) ? <span>✓</span> : null}</li>
                        </ul>
                    </div>


                    <Form.Group>
                        <Form.Check onChange={e => this.handleChange(e)} name="termsAndConditions" placeholder="termsAndConditions" label="I agree to the Terms and Conditions" />
                        {this.state.errors.termsAndConditions ?
                            <Form.Text className="text-danger">
                                {this.state.errors.termsAndConditions}
                            </Form.Text>
                            : null}
                    </Form.Group>

                    <Button variant="primary" type="submit" block>
                       Continue
                        </Button>

                </Form>

            </section>
        )
    }

}



const mapStateToProps = (state, ownProps) => {
    return ({
        ...state,
        location: ownProps.location
    })
}

export default withRouter(connect(mapStateToProps, { login_post, set_password_first_time_put })(SetPassword));