/**
 * @file components/Modals/SuspendUserModel.js
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Modal, Alert } from 'react-bootstrap';
import { suspend_app_user, action_app_user } from '../../actions/appUserAction';
import { PAGES } from "../../constants/pages";
import { RESPONSE_CODE } from '../../constants/errors';

class SuspendUserModel extends React.Component {
    constructor() {
        super();
        this.state = {
            buttonEnabled: true,
            errors:{} 
        }
    }

    actionUser(targetAction)
    {
       console.log('SuspendUserModel : ' + targetAction)
        this.setState({ buttonEnabled: false });
        return this.props.action_app_user(this.props.user.app_user_id, targetAction)
            .then(response => {
                this.setState({ buttonEnabled: true });
                this.props.user.status = targetAction;

                this.closeModal();
            })
            .catch(error => {
                
                if (error.response) 
                {
                    if (error.response.status === RESPONSE_CODE['401_unauthorized']) 
                    {
                        this.props.history.push(PAGES.LOGIN);
                    }
                    else
                    {
                        this.setState({ errors: { system: 'UNEXPECTED_ERROR' } });
                        this.setState({ buttonEnabled: true });
                    
                    }

                  
                }
            })
    }

    closeModal() {
        this.props.hideModal();
        this.setState({ errors: {} });

    }


    render() {
        

       let heading;
       let subtext;
       let action;


       switch(this.props.action)
       {
            case "suspend":
                heading = "SUSPEND"
                subtext="User will not be able to access the account"
                action = "suspend"
                break;

            case "activate":
                heading = "ACTIVATE"
                subtext="User will be activated and will be able to access the app. If this is an old user, all the previous data remains available for the user"
                action = "activate"
                break;

             case "delete":
                heading = "DELETE"
                subtext="User will not be able to access the account. No data will be lost (Soft Delete)"
                action = "delete"

                break;

            default:
                heading = "SuspendDefault"
                subtext="User will not be able to access the account"
                break;
       }
       
        
       const userEmail = (this.props.user != null ? this.props.user.email : "");
       const name = (this.props.user != null ? this.props.user.first_name : "") + " "+(this.props.user != null ? this.props.user.last_name : "");  
        return (
            <Modal show={this.props.showModal} onHide={this.props.hideModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton={false}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {heading} USER
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>You are about to {heading} following user :  </strong></p>
                    <p className="text-capitalize">{name}</p>
                    <p><small> {userEmail} </small></p>
                    <p><small>{subtext}</small></p>
                    <p className='pt-2'><strong>Do you wish to continue ?</strong></p>
                
                	{this.state.errors.system ?
					<Alert variant="danger">
						<p>System Error : Something went wrong.</p>
					</Alert> : null}
                
                
                </Modal.Body>

                <Modal.Footer>
                    <Button disabled={!this.state.buttonEnabled} variant="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                    <Button disabled={!this.state.buttonEnabled} variant="primary" onClick={() => this.actionUser(action)}>Continue</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return ({
        ...state,
        survey: ownProps.survey
    })
}


export default withRouter(connect(mapStateToProps, { suspend_app_user, action_app_user })(SuspendUserModel));
