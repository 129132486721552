import Joi from 'joi';
import 'reflect-metadata';
import { injectable } from "inversify";
import { UPDATE_SPIT_KIT_VALIDATION, CREATE_SPIT_KIT_VALIDATION } from './types/validations/SpitKitValidations';
import { USER_LOOKUP_VALIDATION } from './types/validations/UserLookupValidation';

export const VALIDATION_RULES = {
  UPDATE_SPIT_KIT_VALIDATION,
  USER_LOOKUP_VALIDATION,
  CREATE_SPIT_KIT_VALIDATION,
};

export type ValidationRules = typeof VALIDATION_RULES;

@injectable()
class ValidationService {

  validateData<TInterfaceToValidate>(rulesName: keyof ValidationRules, data: TInterfaceToValidate): Joi.ValidationResult {
    const rules = VALIDATION_RULES[rulesName];
    if (rules === undefined) {
      throw new Error('Unknown Validation Rule');
    }
    const validationResult = rules.validate(data, { abortEarly: false });

    return validationResult;

  }
}

export default ValidationService;
