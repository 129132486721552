import { REDUX_ACTION } from "../constants/reduxAction.js";
import { utils } from '../utils/utils_general';
import { api_user } from '../api/api_user';

// export function consent_user_put() {
//     return (dispatch) => {
//         utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER)
//         const success = (response) => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
//         const fail = error  => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
//         return api_user.consent_user(success, fail)
//     }
// }

export function set_password_first_time_put(password) {
    return (dispatch) => {
        utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER)
        const success = (response) => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
        const fail = error  => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
        return api_user.set_password_first_time(success, fail, password)
    }
}