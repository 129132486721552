/**
 * @file components/login/linkexpired.js
 */

import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faPollH, faPoll, faSitemap, faFileDownload, faFileUpload, faTools, faList, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { dash_kpi } from 'src/actions/reportAction';

import { PAGES } from "src/constants/pages";
import { UNEXPECTED_ERROR, RESPONSE_CODE } from 'src/constants/errors';
import { FEATURE } from 'src/constants/features';

class Dashboard extends React.Component {

	constructor() {
		super();
		this.state = {
			totalUsers: null,
			activeUsers: null,
			pendingUsers: null,
			publishedSurvey: null,
			unpublishedSurvey: null,
			ancestryUsers: null,
			error: {}
		}
	}

	componentDidMount() {
		this.renderDashboard()
	}
	handlerError(error) {
		this.setState({ errors: { system: error } })
	}

	renderDashboard() {

		return this.props.dash_kpi()
			.then(response => {
				console.log(response)
				this.processData(response)
			})
			.catch(error => {
				if (error.response) {
					if (error.response.status === RESPONSE_CODE['401_unauthorized']) {
						this.props.history.push(PAGES.LOGIN);
					}
				}
				else {
					this.handlerError(UNEXPECTED_ERROR)
				}
			})
	}

	processData(response) {

		if (response.app_users) {
			this.setState({ activeUsers: this.validKey(response.app_users, "active") })
			this.setState({ pendingUsers: this.validKey(response.app_users, "pending") })
		}

		if (response.surveys) {
			this.setState({ publishedSurvey: this.validKey(response.surveys, "publish") })
			this.setState({ unpublishedSurvey: this.validKey(response.surveys, "unpublish") })
		}

		if (response.ancestry) {
			const ancestry_log = response.ancestry.description
			const ancestry_log_json = JSON.parse(ancestry_log)
			this.setState({
				ancestryUsers: ancestry_log_json.users.success
			})
		}
	}

	validKey(jsondata, key) {
		const val = jsondata.find(q => q.status === key)
		let count_val

		if (val)
			count_val = val.count
		else
			count_val = 0

		return count_val
	}
	render() {
		return (
			<div className="container">
				<div className="d-sm-flex align-items-center justify-content-between mb-4"></div>
				<p className="mb-0 text-muted text-capitalize">Welcome {this.props.loginUser.first_name}
				</p>

				<div className="row pt-4">

					<div className="col-xl-3 col-md-3 mb-3">
						<div className="card border-left-success  h-100 py-2">
							<div className="card-body">
								<div className="row no-gutters align-items-center">
									<div className="col mr-2">
										<div className="text-xs font-weight-bold text-success text-uppercase mb-1">Active Participants</div>
										<div className="h1 mb-0 font-weight-bold text-muted">{this.state.activeUsers}</div>
									</div>
									<div className="col-auto  fa-2x  text-gray-500">
										<FontAwesomeIcon icon={faUsers} />
									</div>
								</div>
							</div>
						</div>
					</div>

					{FEATURE.ANCESTRY ?
						<div className="col-xl-3 col-md-3 mb-3">
							<div className="card border-left-danger  h-100 py-2">
								<div className="card-body">
									<div className="row no-gutters align-items-center">
										<div className="col mr-2">
											<div className="text-xs font-weight-bold text-danger text-uppercase mb-1">Ancestry Not Available</div>
											<div className="h1 mb-0 font-weight-bold text-muted">{this.state.activeUsers - this.state.ancestryUsers}</div>
										</div>
										<div className="col-auto  fa-2x  text-gray-500">
											<FontAwesomeIcon icon={faSitemap} />
										</div>
									</div>
								</div>
							</div>
						</div> : null
					}

					{FEATURE.SURVEY ?
						<div className="col-xl-3 col-md-3 mb-3">
							<div className="card border-left-info  h-100 py-2">
								<div className="card-body">
									<div className="row no-gutters align-items-center">
										<div className="col mr-2">
											<div className="text-xs font-weight-bold text-info text-uppercase mb-1">Surveys Published</div>
											<div className="h1 mb-0 font-weight-bold text-muted">{this.state.publishedSurvey}</div>
										</div>
										<div className="col-auto  fa-2x  text-gray-500">
											<FontAwesomeIcon icon={faPollH} />
										</div>
									</div>
								</div>
							</div>
						</div> : null
					}

					{FEATURE.SURVEY ?
						<div className="col-xl-3 col-md-3 mb-3">
							<div className="card border-left-warning h-100 py-2">
								<div className="card-body">
									<div className="row no-gutters align-items-center">
										<div className="col mr-2">
											<div className="text-xs font-weight-bold text-warning text-uppercase mb-1">Surveys Unpublished</div>
											<div className="h1 mb-0 font-weight-bold text-muted">{this.state.unpublishedSurvey}</div>
										</div>
										<div className="col-auto  fa-2x  text-gray-500">
											<FontAwesomeIcon icon={faPoll} />
										</div>
									</div>
								</div>
							</div>
						</div> : null
					}
				</div>


				<div className="row pt-4">
					{FEATURE.SURVEY ?
						<div className="col-lg-4">
							<div className="card mb-4">
								<div className="card-body text-center">
									<div className="col-auto  fa-2x  text-gray-400 pt-4">
										<FontAwesomeIcon icon={faPollH} />
									</div>
									<h5 className="text-muted">Surveys</h5>
									<p className="text-muted">All your survey information in one place</p>
								</div>
								<div className="card-body">
									<hr />

									<FontAwesomeIcon icon={faFileUpload} className="text-gray-400" /> &nbsp;
									<a href="/surveys" className="card-link ">
										<strong>Import</strong> new survey</a>
									&nbsp;&nbsp;

									<FontAwesomeIcon icon={faFileDownload} className="text-gray-400" /> &nbsp;
									<a href="/surveys" className="card-link">
										<strong>Export</strong> all surveys</a>
								</div>
							</div>

						</div> : null
					}

					<div className="col-lg-4">

						<div className="card mb-4">

							<div className="card-body text-center">
								<div className="col-auto  fa-2x  text-gray-400 pt-4">
									<FontAwesomeIcon icon={faUsers} />

								</div>
								<h5 className="text-muted">User Management</h5>
								<p className="text-muted">View and manage program participants</p>
							</div>

							<div className="card-body text-center">
								<hr />

								<FontAwesomeIcon icon={faFileUpload} className="text-gray-400" /> &nbsp;
								<a href="/users" className="card-link ">
									<strong>Import</strong> user list</a>

							</div>
						</div>
					</div>

					<div className="col-lg-4">
						<div className="card mb-4">
							<div className="card-body text-left">
								<ul className="list-group list-group-flush">
									{FEATURE.ANCESTRY ?
										<li className="list-group-item">
											<p className="pt-1 ">
												<FontAwesomeIcon icon={faSitemap} className="text-gray-400 fa-2x mr-3" />
												<a href={PAGES.ANCESTRY}>Ancestry</a>
											</p>
										</li> : null
									}
									<li className="list-group-item">
										<p className="pt-1 ">
											<FontAwesomeIcon icon={faList} className="text-gray-400 fa-2x mr-3" />
											<a href={PAGES.SYSTEM_REPORTS}>System Reports</a>
										</p>
									</li>

									<li className="list-group-item">
										<p className="pt-1 ">
											<FontAwesomeIcon icon={faTools} className="text-gray-400 fa-2x mr-3" />
											<a href={PAGES.SYSTEM_CONFIG}>System Config</a>
										</p>
									</li>

									<li className="list-group-item">
										<p className="pt-1 ">
											<FontAwesomeIcon icon={faList} className="text-gray-400 fa-2x mr-3" />
											<a href={PAGES.SPIT_KIT}>DNA Collection Kit</a>
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="row text-center pt-4">
					<div className="col-12 pt-3 pb-3 mr-1 text-secondary">
						<p><FontAwesomeIcon icon={faQuestionCircle} /> <strong>Need Help?</strong> Please contact to the administrator.</p>
					</div>
				</div>
			</div>
		)
	}
}


const mapStateToProps = (state) => {
	return ({
		...state
	})
}

export default connect(mapStateToProps, { dash_kpi })(Dashboard);