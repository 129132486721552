import types, { SpitKitAction, SpitKitDataTypes, SpitKitOrderEntry, SpitKitStatusSummary, Pagination, SpitKitListFilters, PaginationActionData, SpitKitImportResult, SpitKitSelectedOrders, SpitKitBulkOperation } from "./Types";

export type SpitKitReducerState = {
  spitKitListFilters: SpitKitListFilters,
  spitKitList: SpitKitOrderEntry[],
  spitKitListPagination: Pagination,
  spitKitListLoading: boolean,

  spitKitSummary?: SpitKitStatusSummary,
  spitKitSummaryIsLoading: boolean,

  currentSpitKit?: SpitKitOrderEntry,
  errors: Error[],

  spitKitFulfilledList: SpitKitOrderEntry[],
  spitKitListIsFulfillingNewOrders: boolean,
  spitKitImportResult?: SpitKitImportResult,

  spitKitSelectedOrders: SpitKitSelectedOrders,

  spitKitBulkOperations: SpitKitBulkOperation[],
}

const DEFAULT_STATE: SpitKitReducerState = {
  spitKitListFilters: { },
  spitKitList:[],
  spitKitListPagination: { page: 1, pageSize: 10, resultCount: 0, totalCount: 0, totalPages: 0 },
  spitKitListLoading: false,

  spitKitSummaryIsLoading: false,

  errors: [],

  spitKitFulfilledList: [],
  spitKitListIsFulfillingNewOrders: false,

  spitKitSelectedOrders: [],
  spitKitBulkOperations: [],
}

function getData<T extends SpitKitDataTypes>(action: SpitKitAction){
  return action.data as T;
}

export default function spitKitReducer(state: SpitKitReducerState = DEFAULT_STATE, action: SpitKitAction): SpitKitReducerState{

  const { type } = action;

  switch(type){
    case types.SET_SPIT_KIT_LOADING: {
      return {
        ...state,
        spitKitListLoading: true,
      }
    }
    case types.SET_SPIT_KIT_PAGINATION: {
      const paginationChange = getData<PaginationActionData>(action);
      return {
        ...state,
        spitKitListPagination: {
          ...state.spitKitListPagination,
          ...Object.entries(paginationChange)
            .filter(([,value]) => value !== undefined)
            .reduce((accumulate, [k, v]) => ({ ...accumulate, [k]: v }), {})
        },
      }
    }
    case types.SET_SPIT_KIT_LIST: {
      return {
        ...state,
        spitKitListLoading: false,
        spitKitList: getData<SpitKitOrderEntry[]>(action) || [],
      }
    }
    case types.SET_SPIT_KIT_SUMMARY_LOADING: {
      return {
        ...state,
        spitKitSummaryIsLoading: true,
        spitKitSummary: undefined
      }
    }
    case types.SET_SPIT_KIT_SUMMARY: {
      return {
        ...state,
        spitKitSummaryIsLoading: false,
        spitKitSummary: getData<SpitKitStatusSummary>(action),
      }
    }
    case types.SET_CURRENT_SPIT_KIT: {
      return {
        ...state,
        spitKitListLoading: false,
        currentSpitKit: getData<SpitKitOrderEntry>(action),
      }
    }
    case types.SET_SPIT_KIT_ERRORS: {
      return {
        ...state,
        spitKitListLoading: false,
        spitKitListIsFulfillingNewOrders: false,
        spitKitSummaryIsLoading: false,
        errors: getData<Error[]>(action) || [],
      }
    }
    case types.SET_SPIT_KIT_LIST_FILTERS: {
      return {
        ...state,
        spitKitListFilters: {
          ...state.spitKitListFilters,
          ...getData<SpitKitListFilters>(action)
        }
      }
    }
    case types.SET_SPIT_KIT_LIST_IS_FULFILLING_NEW_ORDERS: {
      return {
        ...state,
        spitKitListIsFulfillingNewOrders: true,
      }
    }
    case types.SET_SPIT_KIT_FULFILLED_LIST: {
      return {
        ...state,
        spitKitListIsFulfillingNewOrders: false,
        spitKitFulfilledList: getData<SpitKitOrderEntry[]>(action) || [],
      }
    }
    case types.SET_SPIT_KIT_IMPORT_RESULT: {
      return {
        ...state,
        spitKitImportResult: getData<SpitKitImportResult>(action),
      }
    }
    case types.SET_SPIT_KIT_SELECTED_ORDER_IDS: {
      return {
        ...state,
        spitKitSelectedOrders: getData<SpitKitSelectedOrders>(action) || [],
      }
    }
    case types.SET_SPIT_KIT_BULK_OPERATIONS: {
      return {
        ...state,
        spitKitBulkOperations: getData<SpitKitBulkOperation[]>(action) || [],
      }
    }
    case types.SET_SPIT_KIT_BULK_OPERATION_STATUS: {
      const operation = getData<SpitKitBulkOperation>(action);

      return {
        ...state,
        spitKitBulkOperations: state.spitKitBulkOperations.map(
          (originalOperation) => originalOperation.order_id === operation.order_id ? operation : originalOperation
        ),
      }
    }
  }

  return state;

};