import React from "react";
import { FormControlProps } from "react-bootstrap";
import { Col, Form, Row } from "react-bootstrap";
import PlaceholderComponent from "../Placeholder/PlaceholderComponent";

// These are the props directly used when returning the component: <FormField prop1={} prop2={} />
export type FormFieldComponentProps = {
  controlId?: string,
  label?: string,
  loaded?: boolean,
  as?: React.ElementType,
  displayOnly?: boolean,
  errorMessage?: string,
} & FormControlProps & Record<string, any>

const defaultProps: FormFieldComponentProps = {
  displayOnly: false,
  loaded: true,
}

export type FormFieldProps = FormFieldComponentProps;

function FormField({ 
  controlId, 
  label, 
  as, 
  loaded, 
  errorMessage,
  value,
  displayOnly,
  children,
  ...otherProps
}: FormFieldProps) {

  const Control = as || Form.Control;

  return (
    <Form.Group className={"mb-1"} as={Row} controlId={controlId}>
      <Form.Label className={"font-weight-bold pr-0"} column sm="3">{label}</Form.Label>
      <Col className="pl-0" sm="9">
        {!loaded && <PlaceholderComponent />}
        {loaded && (
          displayOnly ? (children || value) : <Control value={value} isInvalid={!!errorMessage} {...otherProps} />
        )}
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>
  );
}

FormField.defaultProps = defaultProps;

export default FormField;