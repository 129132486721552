import React from "react";
import { Button } from "react-bootstrap";

// @ts-ignore
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { PAGES } from "src/constants/pages";
import { DoDispatch } from "src/services/types/IStore";
import { doSetCurrentSpitKit } from "src/store/SpitKit/SpitKitActions";
import { SpitKitReducerState } from "src/store/SpitKit/SpitKitReducer";
import { selectSpitKitCurrentOrder, } from "src/store/SpitKit/SpitKitSelectors";
import { fullName, newSpitKitOrder, Requester, SpitKitOrderEntry } from "src/store/SpitKit/Types";

type SpitKitCreateSuccessStateProps = Required<Pick<SpitKitReducerState, 'currentSpitKit'>>;

type SpitKitCreateSuccessDispatchProps = {
  setCurrentSpitKit: (spitKit: SpitKitOrderEntry) => void,
}

// These are the props directly used when returning the component: <SpitKitCreateSuccess prop1={} prop2={} />
export type SpitKitCreateSuccessComponentProps = {
}

const defaultProps: SpitKitCreateSuccessComponentProps = {

}

export type SpitKitCreateSuccessProps = SpitKitCreateSuccessComponentProps & SpitKitCreateSuccessStateProps & SpitKitCreateSuccessDispatchProps;

function SpitKitCreateSuccess({ currentSpitKit, setCurrentSpitKit, }: SpitKitCreateSuccessProps) {

  const { email, order_phone, order_id, address_line1, address_line2, city, state, zip } = currentSpitKit;

  const handleBack = () => {
    setCurrentSpitKit(newSpitKitOrder());
  }

  return (
    <>
      <h4 className="text-success">Order Created Successfully</h4>
      <p>
        An email confirmation has been sent to <strong className="text-nowrap">{email}</strong>
      </p>
      <p>
        <strong>Order #:</strong> <a href={`${PAGES.SPIT_KIT_EDIT}/${order_id}`} title="Edit this Order">{order_id}</a>
      </p>
      <strong>Name:</strong>
      <p>{fullName(currentSpitKit as Requester)}</p>

      <strong>Phone Number:</strong>
      <p>{order_phone}</p>

      <strong>Shipping Address:</strong>
      <address>
        {address_line1}<br />
        {(address_line2 || '').length > 0 ? <>{address_line2}<br /></> : ''}
        {city}, {state}, {zip}
      </address>
      <div className="text-right">
        <Button variant="primary" onClick={handleBack}>Create New Order</Button>
      </div>
    </>
  )
}

SpitKitCreateSuccess.defaultProps = defaultProps;

const mapDispatchToProps = (dispatch: DoDispatch): SpitKitCreateSuccessDispatchProps => ({
  setCurrentSpitKit: (spitKit: SpitKitOrderEntry) => dispatch(doSetCurrentSpitKit(spitKit)),
});

const mapStateToProps = createStructuredSelector<any, SpitKitCreateSuccessStateProps>({
  currentSpitKit: selectSpitKitCurrentOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(SpitKitCreateSuccess);