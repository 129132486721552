import React, { useEffect } from "react";

// @ts-ignore
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { PAGES } from "src/constants/pages";
import { DoDispatch } from "src/services/types/IStore";
import { doSetCurrentSpitKit } from "src/store/SpitKit/SpitKitActions";
import { SpitKitReducerState } from "src/store/SpitKit/SpitKitReducer";
import { selectSpitKitCurrentOrder } from "src/store/SpitKit/SpitKitSelectors";
import { hasUser, newSpitKitOrder, SpitKitOrderEntry } from "src/store/SpitKit/Types";
import PageHeadingComponent from "../global/PageHeading/PageHeadingComponent";
import SpitKitCreateForm from "./SpitKitCreateForm";
import SpitKitCreateSuccess from "./SpitKitCreateSuccess";
import SpitKitUserSearch from "./SpitKitUserSearch";

type SpitKitCreateStateProps = Pick<SpitKitReducerState, 'currentSpitKit'>;

type SpitKitCreateDispatchProps = {
  setCurrentSpitKit: (spitKit: SpitKitOrderEntry) => void,
}

// These are the props directly used when returning the component: <SpitKitCreate prop1={} prop2={} />
export type SpitKitCreateComponentProps = {
}

const defaultProps: SpitKitCreateComponentProps = {

}

export type SpitKitCreateProps = SpitKitCreateComponentProps & SpitKitCreateStateProps & SpitKitCreateDispatchProps;

function SpitKitCreate({ currentSpitKit, setCurrentSpitKit }: SpitKitCreateProps) {

  useEffect(() => {
    if(currentSpitKit === undefined){
      setCurrentSpitKit(newSpitKitOrder());
    }
  }, [currentSpitKit, setCurrentSpitKit]);

  const hasSpitKit = currentSpitKit !== undefined;
  const spitKitHasUser = hasSpitKit && hasUser(currentSpitKit);
  const spitKitHasId = (currentSpitKit?.order_id?.length || 0) > 0;

  const showUserSearch = hasSpitKit && !spitKitHasUser;
  const showSpitKitForm = hasSpitKit && spitKitHasUser && !spitKitHasId;
  const showSpitKitSuccess = hasSpitKit && spitKitHasUser && spitKitHasId;

  return (
    <div className="container">
      <div className="mt-2 mb-2">
        <Link to={PAGES.SPIT_KIT}>
          &#x3c; <strong>Back to DNA Collection Kit</strong>
        </Link>
      </div>
      <PageHeadingComponent title={"Create Order"} />
      {showUserSearch && <SpitKitUserSearch />}
      {showSpitKitForm && <SpitKitCreateForm />}
      {showSpitKitSuccess && <SpitKitCreateSuccess />}
    </div>
  )
}

SpitKitCreate.defaultProps = defaultProps;

const mapDispatchToProps = (dispatch: DoDispatch): SpitKitCreateDispatchProps => ({ 
  setCurrentSpitKit: (spitKit: SpitKitOrderEntry) => dispatch(doSetCurrentSpitKit(spitKit)),
});

const mapStateToProps = createStructuredSelector<any, SpitKitCreateStateProps>({
  currentSpitKit: selectSpitKitCurrentOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(SpitKitCreate);