/**
 * @file components/login/consent.js
 */

import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { utils } from '../../utils/utils_general';
import { PAGES } from '../../constants/pages';
import { get_survey_list_get, survey_preview } from '../../actions/surveyAction';
import { download_survey_single_get } from '../../actions/surveyAction';

class SurveyDetail extends React.Component {
    constructor() {
        super();
        this.state = {
            errors: {},
            survey: null,
            currentPage: 1,
            surveyId: null,
            published: false, 
            previewURL:null
        }
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.survey && !utils.is_obj_empty(this.props.location.state.survey)) {
            this.setState({ surveyId: this.props.location.state.survey.survey_id })
            this.setState({ currentPage: this.props.location.state.currentPage }, () => this.getSurveys());
            this.getPreview(this.props.location.state.survey.survey_id)
        }
        else {
            this.props.history.push(PAGES.SURVEYS);
        }
    }

    updateSurveyStatus() {
        this.setState({ errors: "" })
        this.setState({ status: this.state.survey.status })
        this.setState({ published: this.state.survey.status === "publish" ? true : false });
    }
  

    getPreview(sid) {
        return this.props.survey_preview(sid)
            .then(response => {
                this.setState({ previewURL: response.data.preview_url })
            })
            .catch(error => {
                console.log(error)
            })
    }

    getSurveys() {
        return this.props.get_survey_list_get(this.state.currentPage)
            .then(response => {
                this.setState({ survey: response.surveys.find((newSurvey) => newSurvey.survey_id === this.state.surveyId) })
                this.updateSurveyStatus();
            })
            .catch(error => {
                console.log(error)
            })
    }

    handlePublish() {
        this.setState({ modalIsOpen: !this.state.modalIsOpen });
        this.getSurveys();
    }

    renderSurveyPreview() {
        if (!this.state.survey) {
            return null
        }

        // let addModalClose = () => {
        //     this.setState({ modalIsOpen: false });
        //     this.getSurveys();
        // }

        // const surveyStatus = (this.state.published) ? "Unpublish" : "Publish";

        return (

            <section className="surveys container">
                <div className='mt-3 mb-3'>
                    <p className="h4 mb-3">PREVIEW SURVEY: {this.state.survey.title}</p>
                </div>

                <div className="text-small">
                    
                    {this.state.previewURL ? null : <p className="alert alert-primary text-center">Preparing preview</p>}

                    <div className="embed-responsive embed-responsive-16by9">

                        <iframe title="survey-preview" className="embed-responsive-item" src ={this.state.previewURL} width="100%"></iframe>
                    </div>
                </div>
                {this.state.errors.system ?
                    <Alert className="mt-5" variant="danger">
                        <Alert.Heading>Something went wrong</Alert.Heading>
                        <p>
                            {this.state.errors.system}
                        </p>
                    </Alert> : null}
            </section>
        )
    }

    render() {
        return (
            <section className="surveys container">
                <div className="mt-2 mb-2"><Link to={{ pathname: PAGES.SURVEY_DETAIL, state: {survey: this.state.survey, currentPage: this.state.currentPage } }}>&#x3c;<strong>Back to Survey Details</strong></Link></div>
                <React.Fragment>{this.renderSurveyPreview()}</React.Fragment>
            </section>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return ({
        ...state,
        location: ownProps.location
    })
}

export default withRouter(connect(mapStateToProps, { get_survey_list_get, download_survey_single_get , survey_preview})(SurveyDetail));