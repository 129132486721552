import { APP_CONTENT } from "../constants/api";
import { utils_api } from "../utils/utils_api";

export const api_content = {
	get_terms_content(success, fail) {
        return utils_api.auth_get(APP_CONTENT.TERMS_CONTENT_GET, success, fail)
    },

    get_privacy_content(success, fail) {
        return utils_api.auth_get(APP_CONTENT.PRIVACY_CONTENT_GET, success, fail)
    }
}