import { injectable } from "inversify";

export type ExtractorFunction<TSource> = (row: TSource) => unknown;

export type CellExtractor<TSource> = (keyof TSource) | ExtractorFunction<TSource>;

export type ColumnSpec<TSource> = {
  title: string,
  extractor: CellExtractor<TSource>
};

export type CsvExportSettings<TSource> = {
  columns: ColumnSpec<TSource>[],
  includeHeaders?: boolean,
  separator?: string,
}

export interface ICsvService {
  toCsv<TSource>(settings: CsvExportSettings<TSource>, data: TSource[]): string;
}

type NormalizedColumnSpec<TSource> = {
  title: string,
  extractor: ExtractorFunction<TSource>
};

function normalizeExtractors<TSource>(columns: ColumnSpec<TSource>[]): NormalizedColumnSpec<TSource>[] {
  return columns.map(({ title, extractor }) => {
    const normalizedExtract = typeof extractor === 'function' ? extractor : (row: TSource) => row[extractor];
    
    return { 
      title, 
      extractor: normalizedExtract 
    };
  });
}

type Escaper = (data: unknown) => string;

function createEscaper(separator: string): Escaper {
  return (data: unknown) => {
    const dataAsString = String(data || '');
    if (dataAsString.includes(separator)){
      return `"${dataAsString}"`;
    }

    return dataAsString;
  }
}

@injectable()
export class CsvService implements ICsvService {

  toCsv<TSource>(
    { includeHeaders = true, columns, separator = ',' }: CsvExportSettings<TSource>, 
    data: TSource[]
  ): string {
    const normalizedExtractors = normalizeExtractors(columns);
    const escape = createEscaper(separator);

    const headers = includeHeaders ? normalizedExtractors.map(({title}) => escape(title)).join(separator) + '\n' : '';

    const rows = data.map(
      (row) => normalizedExtractors.map(({extractor}) => escape(extractor(row))).join(separator)
    ).join('\n');

    return `${headers}${rows}`;
  }
}