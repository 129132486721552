import { faCheckCircle, faSpinner, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button } from "react-bootstrap";

// @ts-ignore
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import GenericModal, { GenericModalComponentProps } from "src/components/global/GenericModal/GenericModal";
import { OrderActionStatus, SpitKitActionsModalProps } from "src/components/spitKit/SpitKitActionsModal";
import SpitKitStatus from "src/components/spitKit/SpitKitStatus";
import { useInjection } from "src/services/ServicesContext";
import { ISpitKitService } from "src/services/SpitKitService";
import { DoDispatch } from "src/services/types/IStore";
import { doSetSpitKitBulkOperations, doSetSpitKitSelectedOrders } from "src/store/SpitKit/SpitKitActions";
import { SpitKitReducerState } from "src/store/SpitKit/SpitKitReducer";
import { selectSpitKitBulkOperations, selectSpitKitSelectedOrders } from "src/store/SpitKit/SpitKitSelectors";
import { fullName, SpitKitBulkOperation, SpitKitSelectedOrders } from "src/store/SpitKit/Types";

type BulkOperationStatusIconProps = {
  operation?: SpitKitBulkOperation
}

function BulkOperationStatusIcon({ operation }: BulkOperationStatusIconProps) {
  if (operation === undefined) {
    return null;
  }
  switch (operation.operationStatus) {
    case 'running': {
      return (
        <FontAwesomeIcon icon={faSpinner} spin />
      )
    }
    case 'completed': {
      return (
        <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
      )
    }
    case 'failed': {
      return (
        <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
      )
    }
    default: return null;
  }
}

type SpitKitBulkActionModalStateProps = {
} & Pick<SpitKitReducerState,
  | 'spitKitSelectedOrders'
  | 'spitKitBulkOperations'
>

// These are the props directly used when returning the component: <SpitKitBulkActionModal prop1={} prop2={} />
export type SpitKitBulkActionModalComponentProps = {
} & Pick<GenericModalComponentProps,
  | 'show'
  | 'onCancel'
> & Pick<SpitKitActionsModalProps,
  | 'action'
  | 'onActionStatus'
>

export type SpitKitBulkActionModalDispatchProps = {
  clearSpitKitBulkOperations: () => void,
  clearSpitKitSelectedOrders: () => void,
  setSpitKitSelectedOrders: (selectedOrders: SpitKitSelectedOrders) => void,
}

const defaultProps: SpitKitBulkActionModalComponentProps = {
  show: false,
  action: 'delete',
  onCancel: () => { },
  onActionStatus: () => { },
}

export type SpitKitBulkActionModalProps =
  & SpitKitBulkActionModalComponentProps
  & SpitKitBulkActionModalStateProps
  & SpitKitBulkActionModalDispatchProps;

function SpitKitBulkActionModal({
  show,
  action,
  spitKitSelectedOrders,
  spitKitBulkOperations,
  clearSpitKitBulkOperations,
  clearSpitKitSelectedOrders,
  setSpitKitSelectedOrders,
  onCancel,
  onActionStatus
}: SpitKitBulkActionModalProps) {
  const [orderActionStatus, setOrderActionStatus] = useState<OrderActionStatus>();
  const [performed, setPerformed] = useState<boolean>(false);

  const spitKitService = useInjection<ISpitKitService>('spitKitService');

  const handlePerformAction = async () => {
    setOrderActionStatus('started');
    onActionStatus('started');

    switch (action) {
      case "delete":
        await spitKitService.bulkDeleteSpitKitOrders(spitKitSelectedOrders);
        break;
    }

    setPerformed(true);
    setOrderActionStatus('completed');
  }

  const handleFinishAction = () => {
    if (performed) {
      clearSpitKitBulkOperations();
      clearSpitKitSelectedOrders();
      onActionStatus('completed');
      onActionStatus(undefined);
    }
    setPerformed(false);
  }

  const handleCancel = () => {
    handleFinishAction();
    onCancel();
  }

  const handleRemoveOrderFromList = (orderId: string) => {
    setSpitKitSelectedOrders(spitKitSelectedOrders.filter(({order_id}) => order_id !== orderId));
  }

  const actionSealed = orderActionStatus === 'started' || performed;

  return (
    <GenericModal
      show={show}
      size="lg"
      title={<>
        <span className="text-capitalize">{action}</span>
        {' '}
        {spitKitSelectedOrders.length}
        {' '}
        Order(s)
      </>}
      canProceed={orderActionStatus !== 'started' && spitKitSelectedOrders.length > 0}
      canCancel={!actionSealed}
      onCancel={handleCancel}
      onContinue={handlePerformAction}
      isAlternate={performed}
      alternateAction={
        <Button variant="primary" onClick={handleFinishAction}>
          Ok
        </Button>
      }
    >
      <p>
        Are you sure you would like to <strong className="text-capitalize">{action}</strong> the selected order(s)?
      </p>
      <p>
        <strong>This action cannot be undone.</strong>
      </p>

      <table className="table">
        <thead>
          <tr>
            <th></th>
            <th className="text-nowrap">Order ID</th>
            <th className="text-nowrap">Name</th>
            <th className="text-nowrap">Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {spitKitSelectedOrders.length === 0 && (
            <tr>
              <td colSpan={5} className="text-center">
                No Orders Selected
                {' '}
                <Button variant="link" className="p-0 align-baseline" onClick={handleCancel}>Close</Button>
              </td>
            </tr>
          )}
          {spitKitSelectedOrders.map(order => {
            const { order_id, status, ...requester } = order;
            const operation = spitKitBulkOperations.find(({ order_id: orderId }) => order_id === orderId);

            return (
              <tr key={order_id} title={operation?.reason?.message}>
                <td>
                  <BulkOperationStatusIcon operation={operation} />
                </td>
                <td>{order_id}</td>
                <td className="text-nowrap">
                  {fullName(requester)}
                </td>
                <td>
                  <SpitKitStatus status={status} />
                </td>
                <td>
                  {!actionSealed && (
                    <button
                      type="button"
                      className="close"
                      aria-label="Remove order from list"
                      title="Remove order from list"
                      onClick={() => handleRemoveOrderFromList(order_id)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </GenericModal>
  )
}

SpitKitBulkActionModal.defaultProps = defaultProps;

const mapDispatchToProps = (dispatch: DoDispatch): SpitKitBulkActionModalDispatchProps => ({
  clearSpitKitBulkOperations: () => dispatch(doSetSpitKitBulkOperations([])),
  clearSpitKitSelectedOrders: () => dispatch(doSetSpitKitSelectedOrders([])),
  setSpitKitSelectedOrders: (selectedOrders: SpitKitSelectedOrders) => dispatch(doSetSpitKitSelectedOrders(selectedOrders)),
});

const mapStateToProps = createStructuredSelector<any, SpitKitBulkActionModalStateProps>({
  spitKitSelectedOrders: selectSpitKitSelectedOrders,
  spitKitBulkOperations: selectSpitKitBulkOperations,
});

export default connect(mapStateToProps, mapDispatchToProps)(SpitKitBulkActionModal);