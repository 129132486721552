import { API_URL } from "../constants/api";
import { utils_api } from "../utils/utils_api";


export const api_user = {
	// consent_user(success, fail) {
	// 	const data = {
	// 		consent: 'true',
	// 		consent_term_content_id: '1' // TODO: this needs to be pull from db at some point
	// 	}
	// 	return utils_api.auth_put(`${API_URL.USER_PUT}/me`, data, success, fail);
	// },

	set_password_first_time(success, fail, password) {
		const data = { password };
		return utils_api.auth_put(`${API_URL.USER_PUT}/me`, data, success, fail);
	}
}