/**
 * @file components/login/login.js
 */

import React from 'react';
import { connect } from 'react-redux';
import {Link, withRouter } from "react-router-dom"; // eslint-disable-line no-unused-vars
import {Form, Button} from 'react-bootstrap';
import {utils} from '../../utils/utils_general';
import {utils_validation} from '../../utils/utils_validation';
import {login_credential} from '../../utils/validationrules_api_app';

import {login_post} from "../../actions/loginAction"

import { PAGES } from '../../constants/pages';
import { LOGIN_ERROR_DISPLAY, UNEXPECTED_ERROR, RESPONSE_CODE } from '../../constants/errors';
import portalImage from "src/assets/portal.png";

class Login extends React.Component{
    constructor() {
        super();
		this.state = {
            submitted: false,
			email: "",
            password: "",
            errors: {},
            jwt: "",
		}
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({errors: {}})
        const data = {
            email: this.state.email,
            password: this.state.password
        }

        const errors = utils_validation.validate(login_credential, data)
        if (!utils.is_obj_empty(errors)) {
            this.setState({errors})
        } else {
            this.submitLogin(data)
        }
    }

    submitLogin(data) {
        this.props.login_post(data)
        .then(response => {
            if (response.token_type !== 'session') {
                this.setState({errors: {system: LOGIN_ERROR_DISPLAY.SESSION_INVALID}});
            } else if (new Date(response.expiry) < new Date()) {
                this.setState({errors: {system: LOGIN_ERROR_DISPLAY.SESSION_EXPIRED}});
            } else {
                this.props.history.push(PAGES.DASHBOARD);
            }
        })
        .catch(error => {
            const M = LOGIN_ERROR_DISPLAY;
            if (error.response) {
                switch(error.response.status) {
                    case RESPONSE_CODE['410_user_not_found']:
                        this.setState({errors: {system: M.USER_NOT_FOUND}})
                        break;
                    case RESPONSE_CODE['411_wrong_password']:
                        this.setState({errors: {system: M.USER_NOT_FOUND}})
                        break;
                    case RESPONSE_CODE['417_user_pending']:
                        this.setState({errors: {system: M.USER_NOT_FOUND}})
                        break;
                    case RESPONSE_CODE['414_user_lockout']:
                        this.setState({errors: {system: M.USER_LOCKOUT}})
                        break;
                    case RESPONSE_CODE['415_user_suspended']:
                        this.setState({errors: {system: M.USER_SUSPENDED}})
                        break;
                    default:
                        this.setState({errors: {system: UNEXPECTED_ERROR}})
                }
            }
        });
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    renderForm() {
        return (
            <Form noValidate onSubmit={e => this.handleSubmit(e)}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control onChange={e => this.handleChange(e)} name="email" type="email" placeholder="Enter email" />
                    <Form.Text className="text-danger">
                        {this.state.errors.email ? 'Please provide a valid email.' : <br />}
                    </Form.Text> 
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control onChange={e => this.handleChange(e)} name="password" type="password" placeholder="Password" />
                    <Form.Text className="text-danger">
                        {this.state.errors.password ? 'This field is required.' : <br />}
                    </Form.Text>
                </Form.Group>
                
                <div className="mb-3 text-center">
                    <Form.Text className="text-danger">
                        {this.state.errors.system || <br/>}
                    </Form.Text> 
                </div>

                <Button variant="primary" type="submit" block>
                    Login
                </Button>
            </Form>
        )
    }

    
	render(){
		return (
			
    
            <div className="row justify-content-center">
        
              <div className="col-xl-10 col-lg-12 col-md-9">
        
                <div className="card o-hidden border-0 shadow-lg my-5">
                    <div className="card-body p-0">
                    <div className="row">
                        <div className="col-lg-6 d-none d-lg-block bg-login-image"><img src={portalImage} alt="Portal Image"/></div>
                        <div className="col-lg-6">
                        <div className="p-5">
                            <div className="text-center">
                                <h4 className="h4 text-gray-900 mb-4">Portal Login</h4>
                            </div>
                            {this.renderForm()}
                         
                            <hr/>
                            <div className="text-center">
                                <a className="small" href={PAGES.RESET_PASSWORD_SEND_EMAIL}>Forgot Password?</a>
                            </div>
                       
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        
              </div>
        
            </div>
		)
	}
}



const mapStateToProps = (state, ownProps) => {
	return ({
        ...state,
        location: ownProps.location
	})
}

export default withRouter(connect(mapStateToProps, {login_post})(Login));