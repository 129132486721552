import { APP_USERS_API_URL } from '../constants/api';
import { utils_api } from '../utils/utils_api';

export const api_app_user = {
    get_app_user_list(success, fail, pageNum, paginationSize, searchString, sortColumn, sortOrder) {
        const data = pageNum ? { page_num: pageNum, page_size: paginationSize } : null;
        var params = data ? `?${Object.keys(data).map(key => key + '=' + data[key]).join('&')}` : "";
        params = searchString ? params + "&email=" + searchString : params;
        params = params + "&sort_item=" + sortColumn + "&sort_asc=" + sortOrder;
        
        return utils_api.auth_get(APP_USERS_API_URL.USERS_GET + params, success, fail);
    },

    resend_app_invite(success, fail, email) {
        const data = { "email": email};
        return utils_api.auth_post(APP_USERS_API_URL.APP_USER_REINVITE , data, success, fail)
    },

    suspend_app_user(success, fail, userId) {
        const data = {  status: "suspend" }
        return utils_api.auth_put(APP_USERS_API_URL.APP_USER_PUT + "/" + userId, data, success, fail)
    },
    
    activate_app_user(success, fail, userId) {
        const data = {  status: "active" }
        return utils_api.auth_put(APP_USERS_API_URL.APP_USER_PUT + "/" + userId, data, success, fail)
    }

}

