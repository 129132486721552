import {REDUX_ACTION} from "../constants/reduxAction"

const DEFAULT_STATE = null;

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case REDUX_ACTION.SURVEYS_GET:
            return action.data
        case REDUX_ACTION.SURVEYS_POST:
            return action.data
        case REDUX_ACTION.SURVEYS_EXPORT:
                return action.data
        case REDUX_ACTION.SURVEYS_EXPORT_STATUS:
            return action.data
        default:
            return state;
    }
}