import React, { useEffect } from "react";

import { Link, RouteComponentProps, useParams, withRouter } from "react-router-dom";
// @ts-ignore
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { fullName, Requester, SpitKitOrderEntry } from "src/store/SpitKit/Types";
import { selectSpitKitCurrentOrder, selectSpitKitListLoading } from "src/store/SpitKit/SpitKitSelectors";
import { useInjection } from "src/services/ServicesContext";
import { ISpitKitService } from "src/services/SpitKitService";
import PageHeadingComponent from "src/components/global/PageHeading/PageHeadingComponent";
import PlaceholderComponent from "src/components/global/Placeholder/PlaceholderComponent";
import { Col, Row } from "react-bootstrap";
import { PAGES } from "src/constants/pages";
import SpitKitForm from "./SpitKitForm";
import SpitKitOptionsAside from "./SpitKitOptionsAside";

export type SpitKitEditStateProps = {
  order?: SpitKitOrderEntry,
  spitKitLoading: boolean,
}

// These are the props directly used when returning the component: <SpitKitEdit prop1={} prop2={} />
export type SpitKitEditComponentProps = {
}

export type SpitKitEditProps = SpitKitEditComponentProps & RouteComponentProps & SpitKitEditStateProps;

function SpitKitEdit({ order, location, spitKitLoading }: SpitKitEditProps) {

  const { order_id } = useParams<{ order_id: string }>();
  const spitKitService = useInjection<ISpitKitService>("spitKitService");

  useEffect(() => {
    spitKitService.loadSpitKit(order_id);
  }, [spitKitService, order_id, location.key]);

  const { status } = order || {};

  return (
    <div className="container">
      <div className="mt-2 mb-2">
        <Link to={PAGES.SPIT_KIT}>
          &#x3c; <strong>Back to DNA Collection Kit</strong>
        </Link>
      </div>
      <PageHeadingComponent title={status !== undefined ? fullName(order as Requester) : <PlaceholderComponent inline width={60} />} />
      <Row>
        <Col md="10">
          <SpitKitForm order={order} loaded={!spitKitLoading} />
        </Col>
        <Col md="2" as="aside">
          <SpitKitOptionsAside orderId={order_id} status={status} loaded={!spitKitLoading} />
        </Col>
      </Row>
    </div>
  )
}

const defaultProps: SpitKitEditComponentProps = {

}

SpitKitEdit.defaultProps = defaultProps;

const mapDispatchToProps = {};

const mapStateToProps = createStructuredSelector<SpitKitEditStateProps>({
  order: selectSpitKitCurrentOrder,
  spitKitLoading: selectSpitKitListLoading
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SpitKitEdit));