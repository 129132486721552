import React from "react";
import { BadgeProps } from "react-bootstrap";
import { Badge } from "react-bootstrap";
import { RGCStatus } from "src/store/SpitKit/Types";

type RGCStatusDisplay = { text: string } & Pick<BadgeProps, | 'variant'>;

const statusDisplays: Record<RGCStatus, RGCStatusDisplay> = {
  ordered: { variant: "dark", text: "Ordered"},
  in_fulfillment: { variant: "primary", text: "In Fulfillment"},
  shipped: { variant: "success", text: "Shipped"},
  cancelled: { variant: "secondary", text: "Canceled" },
  deleted: { variant: "danger", text: "Deleted" },
};

// These are the props directly used when returning the component: <SpitKitStatus prop1={} prop2={} />
export type SpitKitStatusComponentProps = {
  status: RGCStatus
}

const defaultProps: Partial<SpitKitStatusComponentProps> = {

}

export type SpitKitStatusProps = SpitKitStatusComponentProps;

function SpitKitStatus({ status }: SpitKitStatusProps) {

  const { variant, text, } = statusDisplays[status];

  return (
    <Badge variant={variant}>
      {text}
    </Badge>
  )
}

SpitKitStatus.defaultProps = defaultProps;

export default SpitKitStatus;