/**
 * @file components/Modals/GroupSurveyModal.js
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { group_survey_post } from '../../actions/surveyAction';
import { PAGES } from "../../constants/pages";
import { RESPONSE_CODE } from '../../constants/errors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faStar} from '@fortawesome/free-solid-svg-icons'

class GroupSurveyModal extends React.Component {
    constructor() {
        super();
        this.state = {
            buttonEnabled: true
        }
    }

    groupTheSurvey() 
    {


    
        this.setState({ buttonEnabled: false });
        return this.props.group_survey_post(this.props.survey.survey_id,"important")
            .then(response => {
                this.setState({ buttonEnabled: true });
                this.closeModal();
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === RESPONSE_CODE['401_unauthorized']) {
                        this.props.history.push(PAGES.LOGIN);
                    }
                }
            })
    }

    closeModal() {
        this.props.hideModal();
     
    }


    render() {
        const surveyTitle = (this.props.survey != null ? this.props.survey.title : "");

        return (
            <Modal show={this.props.showModal} onHide={this.props.hideModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton={false}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        HIGHLIGHT SURVEY <span className="text-warning"><FontAwesomeIcon icon={faStar} /> </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>You are about to highlight the survey <b>{surveyTitle}</b>. This survey will appear in the Dashboard Survey Widget.</p>
                    <p>Currently the highlighted survey is <b>{this.props.highSurvey}</b></p>
                    <p className='pt-2'>This action will replace your existing highlighted survey with the new one. Do you wish to continue?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button disabled={!this.state.buttonEnabled} variant="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                    <Button disabled={!this.state.buttonEnabled} variant="primary" onClick={() => this.groupTheSurvey()}>Continue</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return ({
        ...state,
        survey: ownProps.survey
    })
}


export default withRouter(connect(mapStateToProps, { group_survey_post })(GroupSurveyModal));
