import { SURVEY_API_URL } from '../constants/api';
import { utils_api } from '../utils/utils_api';

export const api_survey = {
    get_surveys_list(success, fail, pageNum) {
        const data = pageNum ? { page_num: pageNum, page_size: 50, sort_asc: 0 } : null;
        const params = data ? `?${Object.keys(data).map(key => key + '=' + data[key]).join('&')}` : "";

        return utils_api.auth_get(SURVEY_API_URL.SURVEYS_GET + params, success, fail);
    },
    post_publish_survey(success, fail, surveyId) {
        return utils_api.auth_post(`${SURVEY_API_URL.SURVEY_ROOT}/${surveyId}${SURVEY_API_URL.SURVEY_POST_PUBLISH}`, '', success, fail);
    },
    post_group_survey(success, fail, surveyId, group) {
        const data = { "group_code": group,"survey_ids" : [surveyId]};
        return utils_api.auth_post(SURVEY_API_URL.SURVEY_GROUP, data, success, fail);
    },
    delete_unpublish_survey(success, fail, surveyId) {
        return utils_api.auth_delete(`${SURVEY_API_URL.SURVEY_ROOT}/${surveyId}${SURVEY_API_URL.SURVEY_DEL_PUBLISH}`, '', success, fail);
    },

    get_download_survey_single(success, fail, surveyId) {
        return utils_api.auth_get(`${SURVEY_API_URL.SURVEY_ROOT}/${surveyId}${SURVEY_API_URL.SURVEY_GET_DOWNLOAD}`, success, fail);
    },
    get_survey_preview(success, fail, surveyId) {
        return utils_api.auth_get(`${SURVEY_API_URL.SURVEY_ROOT}${SURVEY_API_URL.SURVEY_PREVIEW}/${surveyId}`, success, fail)
    },
    get_export_surveys(success, fail) {
        return utils_api.auth_get(SURVEY_API_URL.SURVEYS_GET_DOWNLOAD_ALL, success, fail);
    },

    get_export_surveys_status(success, fail, exportToken) {
        return utils_api.auth_get(`${SURVEY_API_URL.SURVEYS_GET_DOWNLOAD_STATUS}/${exportToken}`, success, fail);
    },
    post_import_ls_survey(success, fail, surveyId) {
        return utils_api.auth_post(SURVEY_API_URL.SURVEY_IMPORT_LS, '', success, fail)
    },
    post_import_survey(success, fail, data) {
        return utils_api.auth_post_raw(SURVEY_API_URL.SURVEY_UPLOAD, data, success, fail)
    }
    
}

