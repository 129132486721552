import React from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch, Redirect } from "react-router-dom";
// import moment from "moment";

import history from './history';
import Login from "./components/login/login";
import UnauthHeader from "./components/global/unauth_header";
import AuthHeader from "./components/global/auth_header";
import setPassword from "./components/firstTimeSigin/set_password";
import setPasswordComplete from "./components/firstTimeSigin/set_password_complete";
import LinkExpired from "./components/firstTimeSigin/link_expired";
import ResetPassword from "./components/password/reset_password";
import ResetPasswordEmailSent from "./components/password/reset_password_email_sent";
import ResetPasswordExpired from "./components/password/reset_password_link_expired";
import ResetPasswordUpdate from "./components/password/reset_password_update";
import ResetPasswordComplete from "./components/password/reset_password_complete";
import Footer from "./components/global/footer";

import TermsAndConditions from "./components/content/terms";
import PrivacyPolicy from "./components/content/privacy";

import Surveys from "./components/survey/surveys";
import Polls from "./components/polls/polls";

import SurveyDetail from "./components/survey/survey_detail";
import SurveyPreview from "./components/survey/survey_preview";

import Ancestry from "./components/ancestry/ancestry";


import System from "./components/system/system";
import Reports from "./components/reports/reports";


import PageNotFound from "./components/404";

import AppUsers from "./components/appUsers/users";
import AppUserDetail from "./components/appUsers/user_detail";

import Dashboard from "./components/dashboard/dashboard";

import {Button} from 'react-bootstrap';
import { PAGES } from "./constants/pages";
import { utils } from './utils/utils_general';
import { LOCAL_STORAGE } from './constants/localStorage';
import jwtDecode from 'jwt-decode';

import { set_login_user_from_local_storage } from "./actions/loginAction";
import { export_surveys_status_get } from './actions/surveyAction';
import { ENV_STRING } from './constants/api';
import { RESPONSE_CODE } from './constants/errors';

import Spinner from './components/global/spinner';
import SpitKit from './components/spitKit/SpitKit';
import SpitKitEdit from './components/spitKit/SpitKitEdit';
import SpitKitCreate from './components/spitKit/SpitKitCreate';

const AuthenticatedRoute = ({ component: Component, path, user, LoginComponent, exportFileCheck, downloadStatus}) => {
  const user_jwt = utils.get_local_storage(LOCAL_STORAGE.USER) || null;
  const decoded_user = user_jwt ? jwtDecode(user_jwt) : null;
  if (!user_jwt || !decoded_user || new Date(decoded_user.expiry) < new Date()) {
    utils.clear_local_storage();
    window.location = PAGES.LOGIN;
    return null
  }

  if (!user) {
    return <Route path={path} render={() => <LoginComponent />} />
  } else {
    return <Route path={path} render={() => <Component checkExportStatus={exportFileCheck} isDownloading={downloadStatus} />} />
  }
}

const UnauthenticatedRoute = ({ component: Component, path, user }) => {
  if (user) {
    return <Route path={path} render={() => <Redirect to={PAGES.DASHBOARD} />} />
  } else {
    return <Route path={path} render={() => <Component />} />
  }
}


class App extends React.Component {

  intervalID = 0;

  constructor() {
    super();
    this.state = {
      user: null,
      exportSurveyFile: null,
      exportSurveyid: null,
      isDownloading: false,
      isTimeout:null
    }

    this.checkExportFileIsReady = this.checkExportFileIsReady.bind(this);
    this.initCheckFileStatus = this.initCheckFileStatus.bind(this);
  }

  componentDidMount() {
    const exportSurveyToken = utils.get_local_storage(LOCAL_STORAGE.EXPORT_SURVEY_TOKEN) || null;
    this.checkUserLoggedIn();
    if(exportSurveyToken) {
        this.initCheckFileStatus();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loginUser && this.props.loginUser.token &&
      prevProps.loginUser.token !== this.props.loginUser.token &&
      this.props.loginUser.token_type === 'session') {
      this.setState({ user: this.props.loginUser })
    }
    // logout
    if (utils.is_obj_empty(this.props.loginUser) && this.state.user) {
      this.setState({ user: null })
    }

    const user_jwt = utils.get_local_storage(LOCAL_STORAGE.USER) || null;
    const user = user_jwt ? jwtDecode(user_jwt) : null;
    if (user_jwt && new Date(user.expiry) <= new Date() && user.token_type === 'session') {
      this.checkUserLoggedIn();
    }
  }

  componentWillUnmount() {
		clearInterval(this.intervalID);
	}

  checkUserLoggedIn() {
    const user_jwt = utils.get_local_storage(LOCAL_STORAGE.USER) || null;
    const user = user_jwt ? jwtDecode(user_jwt) : null;

    if (user_jwt && new Date(user.expiry) > new Date() && user.token_type === 'session') {
      this.setState({ user });
      if (utils.is_obj_empty(this.props.loginUser)) {
        this.props.set_login_user_from_local_storage()
      }
    } else if (user_jwt && new Date(user.expiry) > new Date() && user.token_type === 'registration') {
      this.setState({ user: null });
    } else {
      this.setState({ user: null });
      utils.clear_local_storage();
    }
  }

  initCheckFileStatus() {
    const delayTime = 10000;
    const exportSurveyToken = utils.get_local_storage(LOCAL_STORAGE.EXPORT_SURVEY_TOKEN) || null;

    clearInterval(this.intervalID);
    this.setState({isDownloading: true});
    this.setState({ exportSurveyid: exportSurveyToken }, () => { this.checkExportFileIsReady(); });

    //check status after every [delayTime]
    this.intervalID = setInterval(this.checkExportFileIsReady, delayTime);
  }

  checkExportFileIsReady() 
  {
    return this.props.export_surveys_status_get(this.state.exportSurveyid)
      .then(response => {
       
        //alert((new Date()).getTime());
        
        if (response.data.status === "completed") 
        {
          this.setState({isDownloading: false});
          clearInterval(this.intervalID);
          this.setState({ exportSurveyFile: response.data.downloadlink });

         utils.set_local_storage(LOCAL_STORAGE.EXPORT_FILE_SUCCESS_TIME,(new Date()).getTime());
        }
       
        if (response.data.status === "error") 
        {
           
            this.setState({isDownloading: false});
            clearInterval(this.intervalID);
            this.setState({ exportSurveyFile: "error" });

          
        }

      })
      .catch(error => {
        this.downloadReset();
        clearInterval(this.intervalID);
        if (error.response) {
          if (error.response.status === RESPONSE_CODE['401_unauthorized']) {
            this.props.history.push(PAGES.LOGIN);
          }
        }
      })
  }

  downloadReset() 
  {
    this.setState({ exportSurveyFile: null });
    utils.remove_local_storage(LOCAL_STORAGE.EXPORT_SURVEY_TOKEN);
    utils.remove_local_storage(LOCAL_STORAGE.EXPORT_FILE_SUCCESS_TIME);

    this.setState({ isTimeout: false });

  }
  
  handleDownload() 
  {
    
      var curtime=(new Date()).getTime();
      var dtime=utils.get_local_storage(LOCAL_STORAGE.EXPORT_FILE_SUCCESS_TIME);

      if((curtime-dtime) < 900000 )
      {
      document.location.href = this.state.exportSurveyFile;
      this.downloadReset();
      }
      else
      {
        this.setState({ isTimeout: true });
        
      }
	}



  

  renderFileReadyToDownload() 
  {
   if(this.state.exportSurveyFile === "error") 
   {
      return (
      <div className="file-download-container text-center ">
        <h3>Download Error </h3>
        <p>Looks like something is wrong. Please try again and if the error continues please contact to the admin.</p>
        <Button variant="mr-3 btn btn-light" className="mr-3" onClick={e => this.downloadReset()}>Cancel</Button>
      </div>)
   }
    return (
      <div className="file-download-container text-center ">
        <h3>Survey export is now available for download</h3>
        {this.state.isTimeout ? <p>Sorry. The download link is only available for 15 minutes</p>: null }

        <Button className="mr-3 btn btn-light" onClick={() => this.handleDownload()}>Download</Button>
        <Button variant="btn btn-light" className="mr-3" onClick={e => this.downloadReset()}>Cancel</Button>
     
      </div>
    )
  }


  render() {
    const user = this.state.user;
    // const contentHeader=true; 
    return (
      <div className="App">
        <Router history={history}>
          <div className="main">
            {ENV_STRING ? <small className="badge badge-danger environment-indicator">{ENV_STRING}</small> : null}

            {user ? <AuthHeader /> : <UnauthHeader />}

            <Switch>
              <UnauthenticatedRoute user={user} path={PAGES.LOGIN} exact component={Login} />
              <UnauthenticatedRoute user={user} path={PAGES.FIRST_TIME_SET_PASSWORD} exact component={setPassword} />
              <UnauthenticatedRoute user={user} path={PAGES.FIRST_TIME_SET_PASSWORD_COMPLETE} exact component={setPasswordComplete} />
              <UnauthenticatedRoute user={user} path={PAGES.LINK_EXPIRED} exact component={LinkExpired} />

              <UnauthenticatedRoute user={user} path={PAGES.RESET_PASSWORD_SEND_EMAIL} exact component={ResetPassword} />
              <UnauthenticatedRoute user={user} path={PAGES.RESET_PASSWORD_SEND_EMAIL_COMPLETE} exact component={ResetPasswordEmailSent} />
              <UnauthenticatedRoute user={user} path={PAGES.RESET_PASSWORD_EXPIRED} exact component={ResetPasswordExpired} />
              <UnauthenticatedRoute user={user} path={PAGES.RESET_PASSWORD_SET_NEW} exact component={ResetPasswordUpdate} />
              <UnauthenticatedRoute user={user} path={PAGES.RESET_PASSWORD_COMPLETE} exact component={ResetPasswordComplete} />

              <Route path={PAGES.TERMS_AND_CONDITIONS} exact component={TermsAndConditions} />
              <Route path={PAGES.PRIVACY_POLICY} exact component={PrivacyPolicy} />

              <AuthenticatedRoute user={user} path={PAGES.SURVEYS} exact component={Surveys} LoginComponent={Login} exportFileCheck={this.initCheckFileStatus} downloadStatus={this.state.isDownloading} />
              <AuthenticatedRoute user={user} path={PAGES.SURVEY_DETAIL} exact component={SurveyDetail} LoginComponent={Login} exportFileCheck={this.initCheckFileStatus} downloadStatus={this.state.isDownloading}/>
              <AuthenticatedRoute user={user} path={PAGES.SURVEY_PREVIEW} exact component={SurveyPreview} LoginComponent={Login} exportFileCheck={this.initCheckFileStatus} downloadStatus={this.state.isDownloading}/>
              <AuthenticatedRoute user={user} path={PAGES.DASHBOARD} exact component={Dashboard} LoginComponent={Login} exportFileCheck={this.initCheckFileStatus} downloadStatus={this.state.isDownloading}/>
              <AuthenticatedRoute user={user} path={PAGES.APP_USERS} exact component={AppUsers} LoginComponent={Login} exportFileCheck={this.initCheckFileStatus} downloadStatus={this.state.isDownloading}/>
              <AuthenticatedRoute user={user} path={PAGES.APP_USERS_DETAIL} exact component={AppUserDetail} LoginComponent={Login} exportFileCheck={this.initCheckFileStatus} downloadStatus={this.state.isDownloading}/>
              
              <AuthenticatedRoute user={user} path={PAGES.ANCESTRY} exact component={Ancestry} LoginComponent={Login}  />
              <AuthenticatedRoute user={user} path={PAGES.SYSTEM_CONFIG} exact component={System} LoginComponent={Login}  />
              <AuthenticatedRoute user={user} path={PAGES.SYSTEM_REPORTS} exact component={Reports} LoginComponent={Login}  />
              <AuthenticatedRoute user={user} path={PAGES.POLLS} exact component={Polls} LoginComponent={Login}  />
              <AuthenticatedRoute user={user} path={PAGES.SPIT_KIT} exact component={SpitKit} LoginComponent={Login}  />
              <AuthenticatedRoute user={user} path={`${PAGES.SPIT_KIT_EDIT}/:order_id`} exact component={SpitKitEdit} LoginComponent={Login}  />
              <AuthenticatedRoute user={user} path={`${PAGES.SPIT_KIT_CREATE}`} exact component={SpitKitCreate} LoginComponent={Login}  />

              <Route component={PageNotFound} />

            </Switch>
            {this.props.spinner ? <Spinner error={this.props.spinner}></Spinner> : null}
            <Footer />
          </div>
         

        </Router>
        {this.state.exportSurveyFile ?
              this.renderFileReadyToDownload() : null
            }
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return ({
    ...state
  })
}

export default connect(mapStateToProps, { set_login_user_from_local_storage, export_surveys_status_get })(App);