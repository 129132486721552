import React from 'react';
import moment from "moment";

export type SpitKitDateProps = {
  date?: string | null,
  className?: string,
  includeTime?: boolean,
}
export function SpitKitDate({ date, includeTime, className }: SpitKitDateProps) {
  if (date === undefined || date === null) {
    return null;
  }

  const momentDate = moment(date, "YYYY-MM-DDTHH:mm:ss");
  const formattedDate = momentDate.format(`MM-DD-YYYY${includeTime ? ' hh:mm:ssa' : ''}`);

  return (
    <time dateTime={date} className={className}>{formattedDate}</time>
  )
}