import { REPORT } from "../constants/api";
import { utils_api } from '../utils/utils_api';


export const api_system = {
	
    get_config(success, fail) {
        return utils_api.auth_get(REPORT.CONFIG, success, fail)
	}
	
	
}