import { Dispatch } from "redux";
import { Dispatcher } from "src/services/types/IStore";
import types, { PaginationActionData, SpitKitAction, SpitKitBulkOperation, SpitKitImportResult, SpitKitListFilters, SpitKitOrderEntry, SpitKitSelectedOrders, SpitKitStatusSummary } from "./Types";

const doDispatch = (action: SpitKitAction): Dispatcher  => (dispatch: Dispatch) => dispatch(action);

export const doSetSpitKitLoading = (): Dispatcher => doDispatch({ 
  type: types.SET_SPIT_KIT_LOADING 
});

export const doSetSpitKitPagination = (data: PaginationActionData): Dispatcher => doDispatch({
  type: types.SET_SPIT_KIT_PAGINATION,
  data
});

export const doSetCurrentSpitKit = (data: SpitKitOrderEntry): Dispatcher => doDispatch({
  type: types.SET_CURRENT_SPIT_KIT,
  data
});

export const doSetSpitKitListFilters = (data: SpitKitListFilters): Dispatcher => doDispatch({
  type: types.SET_SPIT_KIT_LIST_FILTERS,
  data
});

export const doSetSpitKitList = (data: SpitKitOrderEntry[]): Dispatcher => doDispatch({
  type: types.SET_SPIT_KIT_LIST,
  data
});

export const doSetSpitKitSummaryLoading = (): Dispatcher => doDispatch({ 
  type: types.SET_SPIT_KIT_SUMMARY_LOADING 
});

export const doSetSpitKitSummary = (data: SpitKitStatusSummary): Dispatcher => doDispatch({
  type: types.SET_SPIT_KIT_SUMMARY,
  data
});

export const doSetSpitKitErrors = (data: Error[]): Dispatcher => doDispatch({
  type: types.SET_SPIT_KIT_ERRORS,
  data,
});

export const doSetSpitKitListFulfillingNewOrders = (): Dispatcher => doDispatch({ 
  type: types.SET_SPIT_KIT_LIST_IS_FULFILLING_NEW_ORDERS 
});

export const doSetSpitKitFulfilledList = (data: SpitKitOrderEntry[]): Dispatcher => doDispatch({
  type: types.SET_SPIT_KIT_FULFILLED_LIST,
  data
});

export const doSetSpitKitImportResult = (data?: SpitKitImportResult): Dispatcher => doDispatch({
  type: types.SET_SPIT_KIT_IMPORT_RESULT,
  data,
});

export const doSetSpitKitSelectedOrders = (data: SpitKitSelectedOrders): Dispatcher => doDispatch({
  type: types.SET_SPIT_KIT_SELECTED_ORDER_IDS,
  data,
});

export const doSetSpitKitBulkOperations = (data: SpitKitBulkOperation[]): Dispatcher => doDispatch({
  type: types.SET_SPIT_KIT_BULK_OPERATIONS,
  data,
});

export const doSetSpitKitBulkOperationStatus = (data: SpitKitBulkOperation): Dispatcher => doDispatch({
  type: types.SET_SPIT_KIT_BULK_OPERATION_STATUS,
  data,
});