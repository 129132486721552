/**
 * @file components/login/linkexpired.js
 */

import React from 'react';
import { connect } from 'react-redux';
import {Button} from 'react-bootstrap';
import { PAGES } from '../../constants/pages';

class LinkExpired extends React.Component{
	render(){
		return (
			<section className="linkexpired container-small">
                <section className="signup-link container text-center ">
                    <h1 className="mb-3 mt-3">[Rest Password link has expired]</h1>
                    <div className="mb-5">Informative message about how link has expired after x hours/mins. Will tell participant what the next step.</div>
                </section>
				<div>
					<Button href={PAGES.LOGIN} variant="primary" type="submit" block className="mb-3">
						Login
					</Button>
				</div>
			</section>
		)
	}
}


const mapStateToProps = (state) => {
	return ({
		...state
	})
}

export default connect(mapStateToProps, {})(LinkExpired);
