/**
 * @file components/Modals/publishSurveyModal.js
 */

 import React from 'react';
 import { connect } from 'react-redux';
 import { withRouter } from 'react-router-dom';
 import { Button, Modal, Alert } from 'react-bootstrap';
 import { upload_surveys_post, ls_import_surveys_post } from '../../actions/surveyAction';
 import { import_poll } from '../../actions/pollAction';
 import { PAGES } from "../../constants/pages";
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 
 import { faUpload } from '@fortawesome/free-solid-svg-icons'
 import { UNEXPECTED_ERROR, RESPONSE_CODE } from '../../constants/errors';
 
 class PublishSurveyModal extends React.Component {
     constructor() {
         super();
         this.state = {
             buttonEnabled: false,
             loading: false,
             selectedFile: null,
             errors: {},
             group : null
 
         }
     }
 
     importTheSurvey() {
 
         const data = new FormData()
 
         data.append('form', this.state.selectedFile)
         this.setState({ buttonEnabled: false });
         this.setState({loading: true});
         this.handlerError("");
         return this.props.upload_surveys_post(data)
             .then(response => 
                 {
                 this.setState({ loading: false });
                 //this.closeModal(true);
                 
                 this.setState({ group: response.data.group });
               
 
             })
             .catch(error => {
                 if (error.response) {
                     if (error.response.status === RESPONSE_CODE['401_unauthorized']) {
                         this.props.history.push(PAGES.LOGIN);
                     }
                     else if (error.response.status === RESPONSE_CODE['405_data_invalid']) {
                         this.handlerError("Invalid survey")
 
                     }
                     else {
                         this.handlerError(UNEXPECTED_ERROR)
                     }
 
                     this.setState({ loading: false });
                 }
             })
     }
 
     handlerError(error) {
         this.setState({ errors: { system: error } })
     }
 
     setFile = event => {
         if (event.target.files[0]) {
             this.setState({ buttonEnabled: true });
         }
         this.setState({
             selectedFile: event.target.files[0]
 
         })
 
     }
 
     closeModal(reload) {
         this.props.hideModal(reload);
         this.setState({ group: null });
         this.setState({
             errors: {}
 
         })
 
     }
 
    surveyType(group)
    {
       if(parseInt(group) === 3)
       {
       this.props.import_poll();
       return (<div>
           <h4> Poll uploaded</h4>
           <p>Questions are synched</p>
           </div>
         
         
         )
       }
       else
       {
        
         return (<div>
             <h4> Survey uploaded</h4>
             <Button variant="info" onClick={() => this.closeModal(true)}>Proceed</Button>
 
             </div>
           
           
           )
       }
     
    }
 
   
 
     render() {
 
 
         return (
             <Modal show={this.props.showModal} onHide={this.props.hideModal}
                 aria-labelledby="contained-modal-title-vcenter"
                 centered>
                 <Modal.Header closeButton={false}>
                     <Modal.Title id="contained-modal-title-vcenter">
                         IMPORT SURVEY <FontAwesomeIcon icon={faUpload} />
 
                     </Modal.Title>
                 </Modal.Header>
                 <Modal.Body>
 
                     <input type="file" className="form-control-file" id="surveyFile" onChange={this.setFile} accept=".lss"/> <small>.less files only</small>
                     <p className='pt-2'>Do you wish to continue?</p>
                     
 
                     {this.state.group ?
                         <Alert variant="success">
                             <Alert.Heading></Alert.Heading>
                             
                             {this.surveyType(this.state.group)}
                            
                         </Alert> : null}
                     
                     
                     {this.state.errors.system ?
                         <Alert variant="danger">
                             <Alert.Heading>Error</Alert.Heading>
                             <p>
                                 {this.state.errors.system}
                             </p>
                         </Alert> : null}
 
                     {this.state.loading ?
 
 
                         <div className="spinner-border text-primary" role="status">
                             <span className="sr-only text-center">Loading...</span>
                         </div> : null}
 
 
                 </Modal.Body>
 
                 <Modal.Footer>
 
                     <Button variant="secondary" onClick={() => this.closeModal(false)}>Cancel</Button>
                     <Button disabled={!this.state.buttonEnabled} variant="primary" onClick={() => this.importTheSurvey()}>Continue</Button>
                 </Modal.Footer>
             </Modal>
         );
     }
 }
 
 const mapStateToProps = (state, ownProps) => {
     return ({
         ...state,
         survey: ownProps.survey
     })
 }
 
 
 export default withRouter(connect(mapStateToProps, { import_poll, upload_surveys_post, ls_import_surveys_post })(PublishSurveyModal));
 