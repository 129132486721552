/**
 * @file components/appUsers/users.js
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { utils } from '../../utils/utils_general';
import { Form, Button, Alert } from 'react-bootstrap';
import { UNEXPECTED_ERROR, RESPONSE_CODE } from '../../constants/errors';
import { PAGES } from "../../constants/pages";
import UserList from './users_list';
import { get_app_user_list } from '../../actions/appUserAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers,faEnvelope, faTrash, faLock, faRunning} from '@fortawesome/free-solid-svg-icons'
import { dash_kpi } from '../../actions/reportAction';

class AppUsers extends React.Component {
	constructor() {
		super();
		this.state = {
			errors: {},  
			users: null,
			currentPage: 1,
			prevPage: null,
			totalPages: 1,
			totalUsers: null,
			paginationSize: 50,
			searchString: null,
			sortOrder: 1,
			sortColumn: "first_name",
			activeUsers: null,
			pendingUsers: null,
			harddeleteUsers: null,
			kbaNotVerified:null,
			lockoutUsers: null
		    
		}
	}

    componentDidMount() 
    {
		if (this.props.location.state) {
			this.setState({ currentPage: (this.props.location.state.pageNum) ? this.props.location.state.pageNum : this.state.currentPage });
			this.getUsers(this.props.location.state.pageNum);
		}
		else {
			this.getUsers(this.state.currentPage);
		}

		this.renderKPI()
	}

    getUsers(pageNum,sortColmun,sortOrder) 
    {
		const searchString = utils.get_url_param(this.props.location.search, 'Search');

        return this.props.get_app_user_list(pageNum,this.state.paginationSize,searchString,this.state.sortColumn,this.state.sortOrder)
            .then(response => 
            {
				const currentPage = pageNum;
				var records=response.length;

				console.log("RECORDS",records)

				if(!records)
				{
					this.handlerError("Did not find any record")
					return
				}
				var n,p;
				
				if(records >= 50)
				{
					if(pageNum===1)
					{
						n = pageNum +1;
						p =0;
					}
					else
					{
						n=pageNum +1;
						p = pageNum -1;

					}
			  }
			  else
			  {
				  n=0;
				  p = pageNum-1;
				 
			  }

                const nextPage = n;
                const prevPage =p;
                
             
                this.setState({
					currentPage,
					nextPage,
					prevPage,
                    users: response
				})
                
            })
			.catch(error => {
				if (error.response) {
					if (error.response.status === RESPONSE_CODE['401_unauthorized']) {
						this.props.history.push(PAGES.LOGIN);
					}
					else if (error.response.status === RESPONSE_CODE['405_data_invalid']) 
					{
						this.handlerError("Invalid data. Error in response.")

					}
				}
				else {
					this.handlerError(UNEXPECTED_ERROR)
				}
            })
	}

	reloadUsers(column) {
		if (column !== this.state.sortColumn) {
            this.setState({ sortOrder: 1 })
		}
		else {
			this.setState({ sortOrder: (this.state.sortOrder === 1 ? 0 : 1) });
		}
        this.setState({ sortColumn: column }, () => {
			this.getUsers(1);
		})
	}

	
	
	renderKPI()
	{

		return this.props.dash_kpi()
		.then(response => {
			console.log(response)
		this.processData(response)
		})
		.catch(error => {
			if (error.response) {
				if (error.response.status === RESPONSE_CODE['401_unauthorized']) {
					this.props.history.push(PAGES.LOGIN);
				}
			}
			else {
				this.handlerError(UNEXPECTED_ERROR)
			}
		})
	
	}

processData(response)
{
	this.setState({ totalUsers: response.total_app_users.total_users })

	if(response.app_users)
	{
		this.setState({ activeUsers: this.validKey(response,"active") })
		this.setState({ lockoutUsers: this.validKey(response,"lockout") })
		this.setState({ pendingUsers: this.validKey(response,"pending") })
		this.setState({ harddeleteUsers: this.validKey(response,"hard_delete_request") })
		this.setState({ kbaNotVerified: this.validKey(response,"kba_not_verified") })
		
		
		

	}

	
}
	
	
validKey(response,key)
{
	const val = response.app_users.find(q => q.status === key) 
	let count_val
	
	if(val)
	count_val= val.count
	else
	count_val= 0

	return count_val
}
	
	handlerError(error) {
		this.setState({ errors: { system: error } })
	}
	
	handleNext(e) 
	{
		this.handlerError("");
        this.getUsers(this.state.nextPage);
	}

	handlePrev(e) {
		this.handlerError("");
		this.getUsers(this.state.prevPage);
	}
	
	renderSearch() {
		
		return (
			<div className="row pt-3">
				<div className='col-8'>
				</div>

				<div className='col-4 '>
					<Form noValidate onSubmit={e => this.getUsers(this.state.currentPage)}>

						<Form.Group>
							<Form.Control name="Search" placeholder="Search" />
						</Form.Group>

					</Form>
				</div>
			</div>

		)
	}
    

    renderPagination() {
		if (!this.state.users) {
			return null
		}
		return (
			<div className="row p-3">
				<div className='col-12 text-right'>
					<div className='p-0'>
						{
							this.state.currentPage > 1 ? ((this.state.currentPage - 1) * this.state.paginationSize+1) : 1
						}
						-
						{
							this.state.users.length === this.state.paginationSize ? this.state.currentPage*this.state.paginationSize : (this.state.currentPage-1)*this.state.paginationSize+this.state.users.length
						}
						<Button variant="link" onClick={e => this.handlePrev(e)} className='btn-link nav-arrow p-0' disabled={this.state.prevPage ? false : true}><i className="arrow left"></i></Button>
						<Button variant="link" onClick={e => this.handleNext(e)} className='btn-link nav-arrow p-0' disabled={this.state.nextPage ? false : true}><i className="arrow right"></i></Button>
					
                    </div>
				</div>

			</div >
		)
	}

    renderUserSummary() {
      
       
        if (!this.state.users) {
			return null
		}
		return (

			<div className="row pt-4">

<div className="col-xl-2 col-md-2 mb-2">
			<div className="card border-left-success  h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-success text-uppercase mb-1">Participants </div>
							<div className="h5 mb-0 font-weight-bold text-muted">{this.state.totalUsers}</div>
                    </div>
                    <div className="col-auto  fa-1x  text-gray-300">
					<FontAwesomeIcon icon={faUsers} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
				
			<div className="col-xl-2 col-md-2 mb-2">
			<div className="card border-left-success  h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-success text-uppercase mb-1">Active </div>
							<div className="h5 mb-0 font-weight-bold text-muted">{this.state.activeUsers}</div>
                    </div>
                    <div className="col-auto  fa-1x  text-gray-300">
					<FontAwesomeIcon icon={faRunning} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

			


				<div className="col-xl-2 col-md-2 mb-2">
			<div className="card border-left-success  h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-success text-uppercase mb-1">Pending </div>
							<div className="h5 mb-0 font-weight-bold text-muted">{this.state.pendingUsers}</div>
                    </div>
                    <div className="col-auto  fa-1x  text-gray-300">
					<FontAwesomeIcon icon={faEnvelope} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

			<div className="col-xl-2 col-md-2 mb-2">
			<div className="card border-left-success  h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-success text-uppercase mb-1">Lockout </div>
							<div className="h5 mb-0 font-weight-bold text-muted">{this.state.lockoutUsers}</div>
                    </div>
                    <div className="col-auto  fa-1x  text-gray-300">
					<FontAwesomeIcon icon={faLock} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

			<div className="col-xl-2 col-md-2 mb-2">
			<div className="card border-left-info  h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-info text-uppercase mb-1">KBA Not Verified</div>
							<div className="h5 mb-0 font-weight-bold text-muted">{this.state.kbaNotVerified}</div>
                    </div>
                    <div className="col-auto  fa-1x  text-gray-300">
					<FontAwesomeIcon icon={faUsers} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
		

			<div className="col-xl-2 col-md-2 mb-2">
			<div className="card border-left-danger  h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">Hard Delete Request</div>
							<div className="h5 mb-0 font-weight-bold text-muted">{this.state.harddeleteUsers}</div>
                    </div>
                    <div className="col-auto  fa-1x  text-gray-300">
					<FontAwesomeIcon icon={faTrash} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
		
		

			</div>
			
			
		)
	}

	


	render() {
      
		return (
			<section className="users container">
				<div className='mt-3 mb-3'>
					<p className="mb-3 text-muted h3 mb-0 text-gray-800"> Users &nbsp;&nbsp;
					
					<button className="btn btn-info">Import Users</button>
					</p>
				</div>
               
                <React.Fragment>{this.renderUserSummary()}</React.Fragment>
				<React.Fragment>{this.renderSearch()}</React.Fragment>
                <UserList displayUsers={this.state.users} paginationSize={this.state.paginationSize} sortColumn={this.state.sortColumn} sortOrder={this.state.sortOrder} currentPage={1} errors={this.handlerError.bind(this)}  refreshUsers={this.reloadUsers.bind(this)} />
				
                {this.renderPagination()}

				{this.state.errors.system ?
					<Alert variant="danger">
						<Alert.Heading>No Data</Alert.Heading>
						<p>
							{this.state.errors.system}
						</p>
					</Alert> : null}
				
			</section>
		)
	}
}


const mapStateToProps = (state, ownProps) => {
	return ({
		...state,
		location: ownProps.location
	})
}

export default withRouter(connect(mapStateToProps, { get_app_user_list, dash_kpi})(AppUsers));