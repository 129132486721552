import React from "react";

export type PageHeadingComponentProps = {
  title: string | React.ReactNode,
  children?: React.ReactNode,
}

function PageHeadingComponent({ title, children }: PageHeadingComponentProps) {

  return (
    <div className='mt-3 mb-3'>
      <div className="d-flex flex-wrap mb-3 text-muted mb-0 text-gray-800"> 
        <h3 className="mr-2 mb-0 align-self-center">{title}</h3>
        <div className="flex-fill d-flex flex-wrap align-items-center">
        {children}
        </div>
      </div>
    </div>
  );
}

export default PageHeadingComponent;