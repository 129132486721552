import React from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faPoll } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlaceholderComponent from "src/components/global/Placeholder/PlaceholderComponent";

export type SummaryCardComponentProps = {
  title: string,
  count?: number,
  icon: IconDefinition,
  cols: number,
  isLoading: boolean,
  status:  
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'dark'
  | 'light'
  | 'link'
};

function SummaryCardComponent({ title, count, icon, status, isLoading, cols, }: SummaryCardComponentProps) {
  return (
    <div className={`col-xl-${cols} col-md-${cols} mb-4`}>
      <div className={`card h-100 py-2 border-left-${status}`}>
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              <div className={`text-s font-weight-bold text-${status} text-uppercase mb-1`}>
                {isLoading ? <PlaceholderComponent /> : title}
              </div>
              <div className="h1 mb-0 font-weight-bold text-muted">
                {isLoading ? <PlaceholderComponent inline width={8} /> : count}
              </div>
            </div>
            <div className="col-auto  fa-3x  text-gray-300">
              <FontAwesomeIcon icon={icon} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const defaultProps: SummaryCardComponentProps = {
  title: '',
  count: 0,
  icon: faPoll,
  isLoading: false,
  status: 'info',
  cols: 4
}
SummaryCardComponent.defaultProps = defaultProps;

export default SummaryCardComponent;