import React, { FormEvent, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";

// @ts-ignore
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useInjection } from "src/services/ServicesContext";
import { ISpitKitService } from "src/services/SpitKitService";
import { DoDispatch } from "src/services/types/IStore";
import { UserLookupInterface } from "src/services/types/validations/UserLookupValidation";
import { IUSerService } from "src/services/UserService";
import { useValidate } from "src/services/ValidationHooks";
import { doSetCurrentSpitKit } from "src/store/SpitKit/SpitKitActions";
import { SpitKitReducerState } from "src/store/SpitKit/SpitKitReducer";
import { selectSpitKitCurrentOrder } from "src/store/SpitKit/SpitKitSelectors";
import { SpitKitOrderEntry } from "src/store/SpitKit/Types";
import FormField from "../global/FormField/FormField";

type SpitKitUserSearchStateProps = Required<Pick<SpitKitReducerState, 'currentSpitKit'>>;

type SpitKitUserSearchDispatchProps = {
  setCurrentSpitKit: (spitKit: SpitKitOrderEntry) => void,
}

// These are the props directly used when returning the component: <SpitKitUserSearch prop1={} prop2={} />
export type SpitKitUserSearchComponentProps = {
}

const defaultProps: SpitKitUserSearchComponentProps = {

}

export type SpitKitUserSearchProps = SpitKitUserSearchComponentProps & SpitKitUserSearchStateProps & SpitKitUserSearchDispatchProps;

function SpitKitUserSearch({ currentSpitKit, setCurrentSpitKit }: SpitKitUserSearchProps) {

  const [email, setEmail] = useState<string>();
  const [searching, setSearching] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string>();

  const { getError, clearError, validate } = useValidate<UserLookupInterface>("USER_LOOKUP_VALIDATION");

  const userService = useInjection<IUSerService>('userService');
  const spiKitService = useInjection<ISpitKitService>('spitKitService');

  const handleEmailChange = ({ currentTarget }: React.FormEvent<HTMLInputElement>) => {
    clearError('email');
    setEmail(currentTarget.value);
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setRequestError(undefined);

    if (!validate({ email: email || '' })) {
      return;
    }

    setSearching(true);
    try {
      const user = await userService.findUserByEmail(email || '');

      const { app_user_id, email: foundUserEmail, first_name, last_name, } = user;

      const existingOrders = await spiKitService.getUserSpitKits(app_user_id);

      if (existingOrders.filter(({ status }) => status !== 'deleted').length > 0) {
        setRequestError('User already has existing orders');
        return;
      }

      setCurrentSpitKit({
        ...currentSpitKit,
        app_user_id,
        email: foundUserEmail,
        first_name,
        last_name
      });
    } catch (e) {
      setRequestError(e instanceof Error ? e.message : String(e));
    } finally {
      setSearching(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormField
        controlId="email"
        loaded
        label="Email"
        errorMessage={getError('email') || requestError}
        type="email" value={email} onChange={handleEmailChange}
        placeholder="user@email.com"
      />
      
      <div className="text-right">
        {searching && <Spinner animation="border" />}
        {!searching && <Button disabled={searching && (email || '').length > 0} type="submit">Submit</Button>}
      </div>
    </Form>
  )
}

SpitKitUserSearch.defaultProps = defaultProps;

const mapDispatchToProps = (dispatch: DoDispatch): SpitKitUserSearchDispatchProps => ({
  setCurrentSpitKit: (spitKit: SpitKitOrderEntry) => dispatch(doSetCurrentSpitKit(spitKit)),
});

const mapStateToProps = createStructuredSelector<any, SpitKitUserSearchStateProps>({
  currentSpitKit: selectSpitKitCurrentOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(SpitKitUserSearch);