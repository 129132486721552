import { REDUX_ACTION } from "../constants/reduxAction.js";
import { utils } from '../utils/utils_general';
import { api_report } from '../api/api_report';

export function dash_kpi() {
    return (dispatch) => {
        utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER)
        const success = (response) => {
            utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
            dispatch({
                type: REDUX_ACTION.SURVEYS_GET,
                data: response.data.data
            });
            return response.data.data
        }
        const fail = error  => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
        return api_report.sys_kpi(success, fail)
    }
}

export function survey_kpi(survey_id) {
    return (dispatch) => {
        utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER)
        const success = (response) => {
            utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
            dispatch({
                type: REDUX_ACTION.SURVEYS_GET,
                data: response.data.data
            });
            return response.data.data
        }
        const fail = error  => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
        return api_report.survey_kpi(success, fail,survey_id)
    }
}


export function user_kpi(user_id) {
    return (dispatch) => {
        utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER)
        const success = (response) => {
            utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
            dispatch({
                type: REDUX_ACTION.SURVEYS_GET,
                data: response.data.data
            });
            return response.data.data
        }
        const fail = error  => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
        return api_report.user_kpi(success, fail,user_id)
    }
}

export function get_logs(param) {
    return (dispatch) => {
        utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER)
        const success = (response) => {
            utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
            dispatch({
                type: REDUX_ACTION,
                data: response.data.data
            });
            return response.data.data
        }
        const fail = error  => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
        return api_report.get_logs(success,fail,param)
    }
}


export function get_healthcheck() {
    return (dispatch) => {
        utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER)
        const success = (response) => {
            utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
            dispatch({
                type: REDUX_ACTION,
                data: response.data.data
            });
            return response.data.data
        }
        const fail = error  => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
        return api_report.get_healthcheck(success,fail)
    }
}


