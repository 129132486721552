import React from "react";

export type NoEntriesRowProps = {
  cellClassName?: string,
  colSpan: number,
  children?: React.ReactNode,
}

function NoEntriesRow({ cellClassName, colSpan, children}: NoEntriesRowProps){
  return (
    <tr>
      <td colSpan={colSpan} className={cellClassName} >
        {children}
      </td>
    </tr>
  );
}

const defaultProps: NoEntriesRowProps = {
  colSpan: 1
};

NoEntriesRow.defaultProps = defaultProps;

export default NoEntriesRow