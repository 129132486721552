import React, { useEffect, useReducer, useState } from "react";
import { Form } from "react-bootstrap";
import GenericModal, { GenericModalComponentProps } from "src/components/global/GenericModal/GenericModal";
import { SpitKitCsvExportSettings } from "src/components/spitKit/constants/SpitKitCsvExportSettings";
import { useCsvExporter } from "src/hooks/useCsvExporter";
import { useInjection } from "src/services/ServicesContext";
import { ISpitKitService } from "src/services/SpitKitService";
import { RGCStatus } from "src/store/SpitKit/Types";

// These are the props directly used when returning the component: <SpitKitExportOrdersModal prop1={} prop2={} />
export type SpitKitExportOrdersModalComponentProps = {
  onFinish: () => void,
} & Pick<GenericModalComponentProps,
  | 'show'
  | 'onCancel'
>

const defaultProps: SpitKitExportOrdersModalComponentProps = {
  show: false,
  onCancel: () => { },
  onFinish: () => { },
}

export type SpitKitExportOrdersModalProps = SpitKitExportOrdersModalComponentProps;

type StatusOptionState = Record<RGCStatus, boolean>;

type StatusCheckboxAction = {
  value?: RGCStatus,
  type: 'select' | 'deselect' | 'select-all' | 'deselect-all'
}

const initialStatusOptions: StatusOptionState = {
  ordered: false,
  in_fulfillment: false,
  shipped: false,
  cancelled: false,
  deleted: false,
};

function statusReducer(state: StatusOptionState, { type, value }: StatusCheckboxAction) {
  switch (type) {
    case 'select': {
      if (!value) {
        return state;
      }
      return { ...state, [value]: true };
    }
    case 'deselect': {
      if (!value) {
        return state;
      }
      return { ...state, [value]: false };
    }
    case 'select-all': {
      return {
        ordered: true,
        in_fulfillment: true,
        shipped: true,
        cancelled: true,
        deleted: true,
      }
    }
    case 'deselect-all': {
      return initialStatusOptions;
    }
    default: throw new Error(`Unknown action type '${type}'`);
  }
}

type StatusCheckboxChanged = {
  name: RGCStatus,
  selected: boolean,
}

type StatusCheckboxProps = {
  name: RGCStatus,
  label: string,
  selected: boolean,
  disabled?: boolean,
  onChange: (event: StatusCheckboxChanged) => void,
}

function StatusCheckbox({ name, label, selected, disabled, onChange }: StatusCheckboxProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange({
    name,
    selected: e.target.checked,
  });

  return (
    <Form.Group controlId={name}>
      <Form.Check
        type="checkbox"
        label={label}
        checked={selected}
        disabled={disabled}
        onChange={handleChange}
      />
    </Form.Group>
  )
}

const StatusLabels: Record<RGCStatus, string> = {
  ordered: "Ordered",
  in_fulfillment: "In Fulfillment",
  shipped: "Shipped",
  cancelled: "Cancelled",
  deleted: "Deleted"
}

function SpitKitExportOrdersModal({ show, onCancel, onFinish }: SpitKitExportOrdersModalProps) {

  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [proceeding, setProceeding] = useState<boolean>(false);

  const [statuses, dispatch] = useReducer(statusReducer, initialStatusOptions);

  const spitKitService = useInjection<ISpitKitService>('spitKitService');

  const { exportToCsv } = useCsvExporter(SpitKitCsvExportSettings);

  useEffect(() => {
    setAllSelected(!Object.values(statuses).some((v) => !v))
  }, [statuses])

  const handleCheckboxChanged = ({ name, selected }: StatusCheckboxChanged) => dispatch({
    type: selected ? 'select' : 'deselect',
    value: name,
  });

  const handleSelectAllChanged = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = target;

    setAllSelected(checked);
    dispatch({
      type: checked ? 'select-all' : 'deselect-all',
    });
  };

  const handleExportOrders = async () => {
    setProceeding(true)

    try {
      const selectedStatuses = Object.entries(statuses)
        .filter(([, selected]) => selected)
        .map(([key]) => key as RGCStatus);

      const orders = await spitKitService.exportSpitKitOrders({ statuses: selectedStatuses });

      exportToCsv({
        data: orders,
        fileName: 'Orders.csv',
      });

      onFinish();

    } finally {
      setProceeding(false)
    }
  }

  return (
    <GenericModal
      title="Export Orders"
      show={show}
      canProceed={Object.values(statuses).some(v => v)}
      proceeding={proceeding}
      onCancel={onCancel}
      onContinue={handleExportOrders}
    >
      <Form.Group controlId="all-orders">
        <Form.Check
          type="checkbox"
          label="All Orders"
          checked={allSelected}
          onChange={handleSelectAllChanged}
          disabled={proceeding}
        />
      </Form.Group>

      <>
        {Object.entries(StatusLabels).map(([key, label]) => (
          <StatusCheckbox
            key={key}
            name={key as RGCStatus}
            label={label}
            selected={statuses[key as RGCStatus]}
            disabled={allSelected || proceeding}
            onChange={handleCheckboxChanged}
          />
        ))}
      </>

    </GenericModal>
  )
}

SpitKitExportOrdersModal.defaultProps = defaultProps;

export default SpitKitExportOrdersModal;