/**
 * @file components/password/reset_password_email_sent.js
 */

import React from 'react';
import { connect } from 'react-redux';

class ResetPasswordSendEmailComplete extends React.Component {
    render() {
        console.log(this.state);
        return (
            <section className="reset-password-email-sent container-small">
                <h1 className="mb-3 mt-3 text-center">[Forgot Password]</h1>
                <div className="mb-5 text-center">Password reset instructions have been sent to you at [email address]. Please follow the instructions in the email to create a new password. For your security, the link will only be valid for X days.</div>
                <div className="mb-5 text-center">If you don't receive the email or have further questions, contact <a href="mailto:#todo">customer service</a>.</div>
            </section>
        )
    }
}



const mapStateToProps = (state) => {
    return ({
        ...state
    })
}

export default connect(mapStateToProps, {})(ResetPasswordSendEmailComplete);
