import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import SyncOperationsError from "src/components/system/SyncOperationsError";

// These are the props directly used when returning the component: <SyncOperations prop1={} prop2={} />
export type SyncOperationsComponentProps = {
  operationName: string,
  synchedMessage: React.ReactNode,
  onSync: () => Promise<void>
}

const defaultProps: SyncOperationsComponentProps = {
  operationName: "",
  synchedMessage: "",
  onSync: () => Promise.resolve(),
}

export type SyncOperationsProps = SyncOperationsComponentProps;

function SyncOperations({ operationName, synchedMessage, onSync }: SyncOperationsProps) {

  const [synchronized, setSynchronized] = useState<boolean>(false);
  const [synchronizing, setSynchronizing] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const handleSync = async () => {
    setError(undefined);
    setSynchronizing(true);
    setSynchronized(false);

    try {
      await onSync();
      setSynchronized(true);
    } catch (error) {
      setError((error as Error));
    } finally {
      setSynchronizing(false);
    }
  }

  return (
    <>
      <Button 
        variant="link" 
        style={{ minWidth: 'auto', color: 'black', marginRight: '10px' }}
        className="btn btn-sm btn-outline-success btn-circle"
        onClick={handleSync}
        disabled={synchronizing}
      >
        <FontAwesomeIcon icon={faSync} />
      </Button>
      {operationName}
      {' '}
      {synchronized && <small>{synchedMessage}</small>}
      {synchronizing && <small>Synching..<span className="spinner-border spinner-border-sm"></span></small>}
      <SyncOperationsError error={error}  />
    </>
  )
}

SyncOperations.defaultProps = defaultProps;

export default SyncOperations;