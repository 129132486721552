

    // this is temp, we should be fetching it from S3

import { PASSWORD_FORMAT_ERRORS } from "../constants/errors";

export const utils = {
    is_obj : obj => obj && typeof obj === 'object' && !Array.isArray ( obj ),

    is_obj_empty : obj => Object.keys(obj).length === 0 && typeof obj === 'object',

    set_local_storage: (key, value) => {
		localStorage.setItem(key, JSON.stringify(value));
    },
    get_local_storage: key => {
        return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;
    },
    remove_local_storage: key => {
        localStorage.removeItem(key)
    },
    clear_local_storage: () => {
        localStorage.clear();
    },
    get_url_param: (paramString, key) => {
        const name = key.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]'); //eslint-disable-line no-useless-escape
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        const results = regex.exec(paramString);
        return !results ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },
    check_password_format: password => {
        const passwordErrors = []
        const r_password_case = RegExp('^(?=.*[a-z])(?=.*[A-Z])', 'g');
        if (!r_password_case.test(password)) {
            passwordErrors.push(PASSWORD_FORMAT_ERRORS.CASE)
        }

        const r_password_number = RegExp('^(?=.*[0-9])', 'g');
        if (!r_password_number.test(password)) {
            passwordErrors.push(PASSWORD_FORMAT_ERRORS.NUMBER)
        }

        const r_password_character = RegExp('^(?=.*[!%@#$^&[\\]{}+=\\-~_|])', 'g');
        if (!r_password_character.test(password)) {
            passwordErrors.push(PASSWORD_FORMAT_ERRORS.SPECIAL_CHARACTER)
        }

        const r_password_length = RegExp('^(?=.{8,50}$)', 'g');
        if (!r_password_length.test(password)) {
            passwordErrors.push(PASSWORD_FORMAT_ERRORS.LENGTH)
        }
        return passwordErrors
    },
	loadingSpinner: (dispatch, action, source=null) => {
		dispatch({
			type: action,
			data: source
		});
	},
    redirectTo: (url, newWindow, history) => {
        if (url.includes('http')) {
            if (newWindow) {
                window.open(url, '_blank');
            } else {
                window.location.href = url;
            }
        } else {
            history.push(url);
        }
    }
}
