import { REDUX_ACTION } from "../constants/reduxAction.js";
import { utils } from '../utils/utils_general';
import { api_content } from '../api/api_content';

export function get_terms() {
    return (dispatch) => {
        utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER)
        const success = response => 
        {
            utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
           
            return response.data.data;
        }
        const fail = error => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
        return api_content.get_terms_content(success, fail)
    }
}


export function get_privacy() {
    return (dispatch) => {
        utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER)
        const success = response => 
        {
            utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
           
            return response.data.data;
        }
        const fail = error => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
        return api_content.get_privacy_content(success, fail)
    }
}


