import { REDUX_ACTION } from "../constants/reduxAction.js";
import { utils } from '../utils/utils_general';
import { api_ancestry } from '../api/api_ancestry';

export function upload_ancestry(data) {
    return (dispatch) => {
        utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER)
        const success = response => 
        {
            utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
            return response.data.data;
        }
        const fail = error  => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
        return api_ancestry.upload_ancestry(success, fail, data)
    }
}


export function get_ancestry_logs() {
    return (dispatch) => {
        utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER)
        const success = (response) => {
            utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
            dispatch({
                type: REDUX_ACTION.SURVEYS_GET,
                data: response.data.data
            });
            return response.data.data
        }
        const fail = error  => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
        return api_ancestry.get_ancestry_logs(success,fail)
    }
}

export function ancestry_upload_status_get(uploadToken) {
    return (dispatch) => {
        utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER)
        const success = response => 
        {
            utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
           
            return response.data.data
        }
        const fail = error  => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
        return api_ancestry.get_ancestry_upload_status(success, fail, uploadToken)
     }
 }

