import { REDUX_ACTION } from "../constants/reduxAction.js";
import { utils } from '../utils/utils_general';
import { api_system } from '../api/api_system';






export function get_config() {
    return (dispatch) => {
        utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER)
        const success = (response) => {
            utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
            dispatch({
                type: REDUX_ACTION,
                data: response.data.data
            });
            return response.data.data
        }
        const fail = error  => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE)
        return api_system.get_config(success,fail)
    }
}



