import { CsvExportSettings, ICsvService } from "src/services/CsvService";
import { DownloadDataRequest, IDownloadDataService } from "src/services/DownloadDataService";
import { useInjection } from "src/services/ServicesContext";

export type ExportRequest<TSource> = {
  data: TSource[],
} & Pick<DownloadDataRequest, 'fileName'|'mime'>

export type UseCsvExporterResponse<TSource> = {
  exportToCsv: (request: ExportRequest<TSource>) => void,
}

export function useCsvExporter<TSource>(specs: CsvExportSettings<TSource>): UseCsvExporterResponse<TSource> {
  const csvService = useInjection<ICsvService>('csvService');
  const downloadDataService = useInjection<IDownloadDataService>('downloadDataService');

  return {
    exportToCsv: ({ data, fileName, mime }: ExportRequest<TSource>) => {
      const result = csvService.toCsv<TSource>(specs, data);
      downloadDataService.downloadData({
        data: result,
        fileName,
        mime,
      })
    }
  }
}