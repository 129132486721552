import { POLL } from "../constants/api";
import { utils_api } from "../utils/utils_api";

export const api_poll = {
	import_poll(success, fail) {
		return utils_api.auth_post(POLL.IMPORT_POLL, "", success, fail)
	},
	get_polls(success, fail,pageNum) 
	{
		const data = pageNum ? { page_num: pageNum } : null;
		var params = data ? `?${Object.keys(data).map(key => key + '=' + data[key]).join('&')}` : "";
	    console.log("params",params);	

		return utils_api.auth_get(POLL.POLLS+params, success, fail)
	},

	poll_status(success, fail, pollId, status) {
         const data = { "poll_qn_id": pollId,"status" : status}
		 return utils_api.auth_post(POLL.POLLS_STATUS, data, success, fail)
    }
}