import {REDUX_ACTION} from "../constants/reduxAction"

const DEFAULT_STATE = {};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case REDUX_ACTION.LOGIN_GET:
            return action.data;
        case REDUX_ACTION.LOGIN_POST:
            return action.data;
        case REDUX_ACTION.LOGIN_FROM_LOCAL_STORAGE:
            return action.data;
        case REDUX_ACTION.LOGOUT_GET:
            return DEFAULT_STATE;
        default:
            return state;
    }
}

