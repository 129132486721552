export const UNEXPECTED_ERROR = 'Unexpected error.'

export const RESPONSE_CODE = {
    '200_success' : 200,
    '204_success_nocontent' : 204,
    '401_unauthorized' : 401,
    '404_notfound' : 404,
    '405_data_invalid' : 405,
    '409_data_conflict' : 409,
    '499_fatal_error' : 499,
    
    '410_user_not_found': 410,
    '411_wrong_password': 411,
    '412_token_expired': 412,
    '413_already_active': 413,
    '414_user_lockout': 414,
    '415_user_suspended': 415,
    '417_user_pending': 417,

    '440_token_invalid': 440,
    '441_token_still_active': 441,

    '490_max_reached': 490
}

// LOGIN
export const LOGIN_ERROR_DISPLAY = {
    USER_LOCKOUT: "This account has been locked or disabled. Please contact support if there is an error.",
    USER_NOT_FOUND: 'The email and/or password is incorrect. Please contact support if there is an error.',
    SESSION_INVALID: "Invalid session.",
    SESSION_EXPIRED: "Session expired.",
    USER_SUSPENDED: "This account has been requested for deletion. Please contact support if there is an error."
}

// SIGNUP
export const SIGNUP_ERROR_DISPLAY = {
    TOKEN_INVALID: "Invalid token.",
    TOKEN_EXPIRED: "Sorry your link is expired.",
    MAX_REACHED: "Max request per day reached."
}

// SET PASSWORD
export const SET_PASSWORD_ERROR_DISPLAY = {
    PASSWORD_INVALID: "Invalid password format.",
    PASSWORD_EMPTY: "Password cannot be left empty."
}

export const SET_PASSWORD_ERROR_RESPONSES = {
    PASSWORD_INVALID: "error-invalid-password-format",
    PASSWORD_EMPTY: "error-empty"
}

// PASSWORD FORMAT
export const PASSWORD_FORMAT_ERRORS = {
    CASE: "case",
    NUMBER: "number",
    SPECIAL_CHARACTER: "special_character",
    LENGTH: "length"
}

export const TOKEN_ERROR_DISPLAY = {
    TOKEN_INVALID: "Invalid token.",
    TOKEN_EXPIRED: "Sorry your link is expired."
}


export const RESET_PWD_ERROR_DISPLAY = {
    USER_NOT_FOUND: "User not found. Please contact your Administrator.",
    TOKEN_INVALID: "Invalid token.",
    TOKEN_EXPIRED: "Sorry your link is expired.",
    INVALID_PASSWORD: "Your new password cannot be the same as your current password",
}

export const TERMS_AND_CONDITION_ERROR_DISPLAY = {
    NOT_CHECKED: "Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy",
}

export const SURVEY_ERROR = {
    SURVEY_NOT_FOUND: "The survey is not found. A survey synchronization is required",
}

export const SURVEYS_EXPORT_ERROR = {
    SURVEY_NOT_FOUND: "An error occured while exporting the surveys. Please contact your Administrator.",
}

export const CONTENT_ERROR_DISPLAY = {
    NO_DATA_FOUND: "No data found"
}