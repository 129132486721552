import { range } from "lodash";
import React from "react";
import PlaceholderComponent from "src/components/global/Placeholder/PlaceholderComponent";

export type TableLoadingFillerProps = {
  rows: number,
  cols: number,
}

function TableLoadingFiller({ rows, cols }: TableLoadingFillerProps) {
  return (
    <>
      {range(0, rows).map((row) => (
        <tr key={row}>
          {range(0, cols).map((col) => (
            <td key={col}>
              <PlaceholderComponent />
            </td>
          ))}
        </tr>
      ))}
    </>
  )
}

const defaultProps: TableLoadingFillerProps = {
  cols: 1,
  rows: 2
};

TableLoadingFiller.defaultProps = defaultProps;

export default TableLoadingFiller;