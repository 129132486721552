import { API_URL } from "../constants/api";
import { utils_api } from "../utils/utils_api";


export const api_login = {
	get_login(success, fail) {
        return utils_api.auth_get(API_URL.LOGIN_GET, success, fail);
    },
	login(success, fail, data) {
        return utils_api.unauth_post(API_URL.LOGIN_POST, data, success, fail);
    }
}